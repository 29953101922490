import { AcceptModal } from '@axo/mypage/ui/atom';
import { breadcrumbs, MyPageFlags } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { LenderLogo } from '@axo/shared/ui/atoms/LenderLogo';
import { Heading } from '@axo/ui-core/components/typography';

import { Explainer } from '@axo/insurance/feature/providers/no';

import { useFlags } from '@axo/shared/services/feature-flags';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import styles from './OfferSelectionModal.module.scss';

const AcceptOfferButton = ({
  header,
  content,
  onClick,
}: {
  header: ReactNode;
  content: ReactNode | string;
  onClick: () => void;
}) => (
  <div role="button" className={styles.productCard} onClick={onClick}>
    <div className={styles.header}>{header}</div>
    <div className={styles.content}>{content}</div>
    <div className={styles.icon}>
      <Icon name="chevron-right" size="s" />
    </div>
  </div>
);

export interface StoredOffer {
  offer: loan_quote_presentation.PresentedLoanQuote;
  acceptableIndex: number;
}

type TAcceptOfferModal = {
  offer: StoredOffer;
  closeModal: () => void;
  marketCountry: string;

  onAcceptInsurance?: React.ComponentProps<
    typeof Explainer
  >['onAcceptInsurance'];
  onDeclineInsurance?: React.ComponentProps<
    typeof Explainer
  >['onDeclineInsurance'];

  isLoadingAccept?: boolean;
};

/** @deprecated */
export function OfferSelectionModal({
  offer,
  closeModal,
  marketCountry,
  onAcceptInsurance,
  onDeclineInsurance,
  isLoadingAccept,
}: TAcceptOfferModal) {
  const log = useEventLogger(...breadcrumbs(offer.offer));
  const { t } = useTranslation();

  const {
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    flag_show_insurance_on_accept,
  } = useFlags(MyPageFlags);

  useEventResource(KnownResourceType.Lender, offer.offer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, offer.offer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, offer.offer.ID);

  const _onAcceptInsurance = useCallback(async () => {
    if (onAcceptInsurance) {
      onAcceptInsurance();
    }
  }, [onAcceptInsurance]);

  const _onDeclineInsurance = useCallback(async () => {
    if (onDeclineInsurance) {
      onDeclineInsurance();
    }
    closeModal();
  }, [onDeclineInsurance, closeModal]);

  useEffect(() => {
    log(EventCode.OpenAcceptOfferModal);
  }, [log]);

  const onClose = useCallback(() => {
    log(EventCode.CloseAcceptOfferModal);
    closeModal();
  }, [log, closeModal]);

  const acceptModalExtraProps = useMemo(() => {
    if (flag_offer_insurance_on_accept) return {};

    return {
      actionBar: {
        buttons: (
          <Button
            variant="ghost"
            onClick={onClose}
            size="small"
            disabled={isLoadingAccept}
            loading={isLoadingAccept}
          >
            {t('Change offer selection')}
          </Button>
        ),
      },
    };
  }, [flag_offer_insurance_on_accept, onClose, t, isLoadingAccept]);

  return (
    <AcceptModal
      open={!!offer}
      data-testid="accept-offer-modal"
      classes={{
        content: flag_offer_insurance_on_accept
          ? styles.modalContent
          : undefined,
      }}
      {...acceptModalExtraProps}
    >
      <Heading size="xs" className={styles.heading}>
        {flag_offer_insurance_on_accept
          ? t('Congratulations on you offer')
          : t('Confirm your selection')}
      </Heading>
      {flag_offer_insurance_on_accept && (
        <>
          <LenderLogo
            lender={offer.offer.Lender}
            marketCountry={marketCountry}
            className={styles.lenderLogo}
          />
          <Heading size="xs" className={styles.subHeading}>
            {t('Would you also like to see our income insurance offer?')}
          </Heading>
          <Explainer
            onAcceptInsurance={_onAcceptInsurance}
            onDeclineInsurance={_onDeclineInsurance}
          />
        </>
      )}
      {!flag_offer_insurance_on_accept && (
        <>
          <AcceptOfferButton
            header={
              <LenderLogo
                lender={offer.offer.Lender}
                marketCountry={marketCountry}
              />
            }
            content={t('Proceed to loan agreement', [offer.offer.Lender.Name])}
            onClick={_onDeclineInsurance}
          />
          {flag_show_insurance_on_accept && (
            <AcceptOfferButton
              header={
                <>
                  <LenderLogo
                    lender={offer.offer.Lender}
                    marketCountry={marketCountry}
                  />
                  <div className={styles.addition}>
                    +
                    <Icon name="shield-solid" size="xs" />
                    {t('Axolaina Insurance')}
                  </div>
                </>
              }
              content={t('Proceed to income insurance', [
                offer.offer.Lender.Name,
              ])}
              onClick={_onAcceptInsurance}
            />
          )}
        </>
      )}
    </AcceptModal>
  );
}
