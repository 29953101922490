import { Badge } from '@axo/ui-core/components/Badge';
import { Stack } from '@axo/ui-core/components/layout/item';
import { clsx } from 'clsx';
import { FC } from 'react';

import styles from './offerAnnotation.module.scss';

export type OfferAnnotationType = 'loanType' | 'documentation';

export type OfferAnnotationProps = {
  labels: Partial<Record<OfferAnnotationType, string>>;
  className?: string;
};

export const OfferAnnotation: FC<OfferAnnotationProps> = ({
  labels,
  className,
}) => {
  return (
    <Stack
      className={clsx(styles.offerAnnotation, className)}
      direction={'h'}
      gap={'3xs'}
      align={'start'}
    >
      {labels.loanType && (
        <Badge size={'s'} variant={'info'} borderless>
          {labels.loanType}
        </Badge>
      )}
      {labels.documentation && (
        <Badge size={'s'} variant={'neutral'} borderless>
          {labels.documentation}
        </Badge>
      )}
    </Stack>
  );
};
