import { Icon, IconName } from '@axo/ui-core/components/Icon';
import { Text } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { ReactElement, ReactNode } from 'react';

import styles from './uspsListItem.module.scss';

type USPsListItemProps = {
  children?: ReactNode;
  className?: string;
  iconName?: IconName;
} & HTMLDataAttributes;

/**
 * USPsListItem component
 */
export const USPsListItem = ({
  children,
  className,
  iconName = 'check',
  ...props
}: USPsListItemProps): ReactElement => {
  return (
    <li className={clsx(styles.uspsListItem, className)} {...props}>
      <Icon name={iconName} />
      <Text>{children}</Text>
    </li>
  );
};
