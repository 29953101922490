import { StatusGroup } from '@axo/shared/data-access/types';

interface CustomerKeyParams {
  customerID?: string | null;
  offset?: number;
  limit?: number;
  groups?: StatusGroup[];
}

export const customerKeys = {
  root: (params: CustomerKeyParams) => ['customer', params.customerID] as const,
  byEmail: (email: string) => ['customer', email] as const,
  consent: (params: CustomerKeyParams) =>
    [...customerKeys.root(params), 'consent'] as const,
  events: (params: CustomerKeyParams) =>
    [...customerKeys.root(params), 'customer events'] as const,
  loanApplications: (params: CustomerKeyParams) =>
    [
      ...customerKeys.root(params),
      'loan applications',
      params.offset,
      params.limit,
      ...(params.groups || []),
    ] as const,
};

export function parseCustomerKey<T extends keyof typeof customerKeys>(
  queryKey: ReturnType<(typeof customerKeys)[T]>
) {
  const [, customerID, , offset, limit, ...groups] = queryKey;

  return {
    customerID,
    offset,
    limit,
    groups,
  };
}
