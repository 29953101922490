import {
  LenderProduct,
  Product,
} from '@axo/shared/data-access/types/loan_quote/LoanQuote';
import { LoanType } from '@axo/shared/data-access/types/loan_quote/LoanType';
import { z } from 'zod';

const ProductEnum = z.nativeEnum(Product);
const LoanTypeEnum = z.nativeEnum(LoanType);

const QuoteStateEnum = z.enum(['selectable', 'accepted', 'cancelled']);
export type _QuoteState = z.infer<typeof QuoteStateEnum>;

const LenderSchema = z.object({
  ID: z.string(),
  Name: z.string(),
  ShortName: z.string(),
  Info: z
    .array(
      z.object({
        InfoText: z.string(),
      })
    )
    .optional(),
  CreditCardInterestFreeDays: z.number().default(0),
});

/** common */

// data from ↓
// import { LoanQuote } from '@axo/shared/data-access/types/loan_quote/LoanQuote';
// import { EnrichedLoanQuote } from '@axo/shared/data-access/types/loan_quote_presentation/EnrichedLoanQuote';
// import { PresentedLoanQuote } from '@axo/shared/data-access/types/loan_quote_presentation/PresentedLoanQuote';

const LoanQuoteBaseSchema = z.object({
  ID: z.string(),
  LoanRequestID: z.string(),
  // LoanApplicationID: z.string(),
  LoanApplicationID: z.string().optional(), // ??? actually needed ?

  Product: ProductEnum,
  MarketCountry: z.string(),

  LenderID: z.string(),
  Lender: LenderSchema,

  Amount: z.number().nonnegative(),
  EffectiveRate: z.number().nonnegative(),

  Status: z.string(),
  Recommended: z.boolean(),

  _QuoteState: QuoteStateEnum, // loan-quote-presentation 3 arrays
});

/** UnsecuredLoan */

const UnsecuredLoanBaseSchema = LoanQuoteBaseSchema.extend({
  Product: z.literal(Product.UnsecuredLoan),

  // LoanType: LoanTypeEnum,
  LoanType: LoanTypeEnum.optional(), // FI breaks

  Duration: z.number().positive(),
  DurationMonths: z.number().nonnegative().default(0),
  MonthlyPayment: z.number().nonnegative(),
  NominalRate: z.number().nonnegative(),

  StartupFee: z.number().nonnegative().default(0), // fallback when missing
  AdministrationFee: z.number().nonnegative().default(0), // fallback when missing
  RequiresDocumentation: z.boolean().default(false),

  RefinanceAmount: z.number().nonnegative().optional(),
  TopupAmount: z.number().nonnegative().optional(),
  CashAmount: z.number().nonnegative().optional(),
  LenderProduct: z.union([z.nativeEnum(LenderProduct), z.string()]).optional(),
});

export const UnsecuredLoanSchema = UnsecuredLoanBaseSchema;
export type UnsecuredLoanQuote = z.infer<typeof UnsecuredLoanSchema>;

/** CreditCard */

export const CreditCardSchema = LoanQuoteBaseSchema.extend({
  Product: z.literal(Product.CreditCard),

  CreditCardInterestFreeDays: z.number().default(0),
});
export type CreditCardQuote = z.infer<typeof CreditCardSchema>;

/** discriminated union */

// https://zod.dev/README?id=discriminated-unions
export const EnrichedLoanQuoteSchema = z.discriminatedUnion('Product', [
  UnsecuredLoanSchema,
  CreditCardSchema,
]);
export type EnrichedLoanQuote = z.infer<typeof EnrichedLoanQuoteSchema>;
