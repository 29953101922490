import { ConfigService } from '../../../config/ConfigService';
import type { WindowWithPayex } from '../payex-service.types';

type InjectPayexScript = {
  scriptSrc: string;
  rootDivId: string;
  onPaymentComplete?: () => void;
  onScriptLoaded?: () => void;
  onError?: OnErrorEventHandler;
};

export const injectPayexScript = ({
  scriptSrc,
  rootDivId,
  onPaymentComplete,
  onScriptLoaded,
  onError,
}: InjectPayexScript) => {
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.async = true;

  script.onload = () => {
    onScriptLoaded?.();

    (window as WindowWithPayex)?.payex?.hostedView
      .checkout({
        container: { checkout: rootDivId },
        culture: ConfigService.config.i18n.defaultLocale,
        onPaid: onPaymentComplete,
      })
      .open();
  };

  script.onerror = onError || null;

  document.body.appendChild(script);

  return script;
};
