import type { GlobalConfig } from '../@types/context/global-config.types';
import type { MyPageConfig } from '../@types/context/my-page-config.types';
import type { SiteConfig } from '../@types/context/site-config.types';
import { globalDk } from './presets/global-dk.config';
import { myPageOffersConfig } from './presets/mypage-offers.config';
import { organisationContactLendmeDkConfig } from './presets/organisation-contact-lendme-dk.config';
import { serviceTrustpilotLendmeDkConfig } from './presets/service-trustpilot-lendme-dk.config';

export const global: GlobalConfig = {
  brand: 'lendme.dk',
  ...globalDk,
};

export const site: SiteConfig = {
  domain: 'lendme.dk',
  theme: {
    name: 'lendme',
    brand: 'lendme',
  },
  path: {
    loansApply: '/loans/apply',
    mypage: '/mypage',
  },
  ...organisationContactLendmeDkConfig,
  services: {
    trustpilot: serviceTrustpilotLendmeDkConfig,
  },
};

export const myPage: MyPageConfig = {
  options: { hasHighCostWarning: false, ...myPageOffersConfig },
};
