import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { FC } from 'react';
import { USPsList } from '../../../../components/USPsList';
import { OfferData } from '../../offer.types';

import styles from './offerInformation.module.scss';

export type OfferInformationProps = {
  state?: Extract<
    OfferData['state'],
    'neutral' | 'canceled' | 'withdrawn' | 'expired'
  >;
  labels: {
    title?: string;
    description?: string;
    list?: string[];
  };
  className?: string;
};

export const OfferInformation: FC<OfferInformationProps> = ({
  state = 'neutral',
  labels,
  className,
}) => {
  return (
    <Stack
      className={clsx(styles.offerInformation, className)}
      data-state={state}
      gap={'4xs'}
    >
      {labels.title && (
        <Text className={styles.title} size={'xs'}>
          {labels.title}
        </Text>
      )}
      {labels.description && (
        <Text
          className={styles.description}
          size={'xs'}
          dangerouslySetInnerHTML={{ __html: labels.description }}
        />
      )}
      {labels.list?.length && (
        <USPsList gap={'4xs'} className={styles.list}>
          {labels.list.map((item) => (
            <USPsList.Item key={item}>{item}</USPsList.Item>
          ))}
        </USPsList>
      )}
    </Stack>
  );
};
