import { TFunction } from 'i18next';

/**
 *
 *
 * @usage
 *
 * **1. `monthsOnly`**
 * ```typescript
 * formatDuration(12, t, { style: 'monthsOnly' }); // "12 months"
 * formatDuration(18, t, { style: 'monthsOnly' }); // "18 months"
 * formatDuration(60, t, { style: 'monthsOnly' }); // "60 months"
 * ```
 *
 * **2. `monthsOnly + threshold: 4`**
 * ```typescript
 * formatDuration(18, t, { style: 'monthsOnly', threshold: 4 }); // "18 months"
 * formatDuration(60, t, { style: 'monthsOnly', threshold: 4 }); // "5 years"
 * ```
 *
 * **3. `mixed`**
 * ```typescript
 * formatDuration(12, t, { style: 'mixed' }); // "1 year"
 * formatDuration(30, t, { style: 'mixed' }); // "2 years 6 months"
 * formatDuration(6, t, { style: 'mixed' });  // "6 months"
 * ```
 *
 * **4. `compact`**
 * ```typescript
 * formatDuration(12, t, { style: 'compact' }); // "1 year" (full years only)
 * formatDuration(18, t, { style: 'compact' }); // "18 months" (total months for non-full years)
 * formatDuration(24, t, { style: 'compact' }); // "2 years" (full years only)
 * formatDuration(30, t, { style: 'compact' }); // "30 months" (total months for mixed case)
 * ```
 */

export function formatDuration(
  totalMonths: number,
  t: TFunction,
  options: {
    style?: 'mixed' | 'monthsOnly' | 'compact';
    threshold?: number;
  } = {}
): string {
  const { style = 'mixed', threshold = 4 } = options;

  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  const parts = [];

  // Case 1: "monthsOnly"
  if (style === 'monthsOnly') {
    if (threshold && years >= threshold) {
      // If threshold is exceeded, switch to returning full years
      parts.push(t('labels.year', { count: years }));
    } else {
      // Otherwise, always return total months
      parts.push(t('labels.month', { count: totalMonths }));
    }
  }

  // Case 2: "mixed"
  if (style === 'mixed') {
    if (months === 0) {
      // Full years only
      parts.push(t('labels.year', { count: years }));
    } else if (years > 0) {
      // Mixed format: years + months
      parts.push(t('labels.year', { count: years }));
      parts.push(t('labels.month', { count: months }));
    } else {
      // Some months but no full years
      parts.push(t('labels.month', { count: totalMonths }));
    }
  }

  // Case 3: "compact"
  if (style === 'compact') {
    if (months === 0) {
      // Full years only
      parts.push(t('labels.year', { count: years }));
    } else {
      // Otherwise, show the number of total months
      parts.push(t('labels.month', { count: totalMonths }));
    }
  }

  return parts.join(' ');
}
