import { getPerson } from '@axo/shared/data-access/api/person';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getInsuranceEligibility,
  TGetInsuranceEligibilityParams,
} from './utils/getInsuranceEligibility';

type TInsuranceEligibilityContext = {
  isEligible: boolean | undefined;
  isLoading: boolean;
};

const InsuranceEligibilityContext = createContext<
  TInsuranceEligibilityContext | undefined
>(undefined);

type TInsuranceEligibilityProviderProps = {
  children: React.ReactNode;
  apiUrl: string;
} & Partial<
  Pick<
    TGetInsuranceEligibilityParams,
    'marketCountry' | 'employmentStatus' | 'personID'
  >
>;

/** @deprecated */
export const InsuranceEligibilityContextProvider = ({
  children,
  apiUrl,
  employmentStatus,
  marketCountry,
  personID,
}: TInsuranceEligibilityProviderProps) => {
  // avoid multiple requests
  const requestStarted = useRef(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEligible, setIsEligible] = useState<boolean | undefined>(undefined);

  const {
    state: {
      user: { token },
    },
  } = useContext(DataAccessContext);

  const _setIsNotEligible = useCallback(() => {
    setIsLoading(false);
    setIsEligible(false);
  }, []);

  const _getInsuranceEligibility = useCallback(async () => {
    if (!personID || !employmentStatus || !marketCountry || !token) {
      _setIsNotEligible();
      return;
    }

    const person = await getPerson({
      token,
      url: {
        service: apiUrl,
      },
    })(personID);

    if (!person.Age) {
      _setIsNotEligible();
      return;
    }

    const insuranceEligibility = await getInsuranceEligibility({
      apiUrl,
      employmentStatus,
      marketCountry,
      personAge: person.Age ?? 0,
      personID,
      token,
    });

    setIsEligible(insuranceEligibility);

    setIsLoading(false);
  }, [
    apiUrl,
    employmentStatus,
    marketCountry,
    personID,
    token,
    _setIsNotEligible,
  ]);

  useEffect(() => {
    if (requestStarted.current) return;

    requestStarted.current = true;
    _getInsuranceEligibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({ isEligible, isLoading }),
    [isEligible, isLoading]
  );

  return (
    <InsuranceEligibilityContext.Provider value={value}>
      {children}
    </InsuranceEligibilityContext.Provider>
  );
};

export const useInsuranceEligibilityContext = () => {
  const context = useContext(InsuranceEligibilityContext);
  if (!context) {
    throw new Error(
      'useInsuranceEligibilityContext must be used within a InsuranceEligibilityContextProvider'
    );
  }
  return context;
};
