import { z } from 'zod';
import { creditCardCodes } from '../../../components/Partners/CreditCard';
import { CreditCardOfferData, Offer, OfferStates } from '../offer.types';
import { CreditCardQuote, CreditCardSchema } from './loan-quote.api.schema';
import { getCreditCardCode } from './utils/get-credit-card-code';
import { getStatusFromLoanQuote } from './utils/get-status-from-loan-quote';

const CreditCardOfferDataSchema: z.ZodType<CreditCardOfferData> = z.object({
  id: z.string(),
  state: z.enum(OfferStates),
  creditCard: z.enum(creditCardCodes),
  Amount: z.number(),
  InterestRate: z.number(),
  InterestFreeDays: z.number(),
});

export const CreditCardOfferTransform = (data: CreditCardQuote) =>
  ({
    type: 'credit-card',
    data: CreditCardOfferDataSchema.parse({
      id: data.ID,
      state: getStatusFromLoanQuote(data),
      creditCard: getCreditCardCode(data),
      Amount: data.Amount,
      InterestRate: data.EffectiveRate,
      InterestFreeDays:
        data.CreditCardInterestFreeDays > 0
          ? data.CreditCardInterestFreeDays
          : data.Lender.CreditCardInterestFreeDays,
    }),
  } as Offer);

export const CreditCardOfferSchema = CreditCardSchema.transform(
  CreditCardOfferTransform
);
