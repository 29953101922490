import type { BadgeProps } from '@axo/ui-core/components/Badge';
import { Badge } from '@axo/ui-core/components/Badge';
import { Icon, IconName } from '@axo/ui-core/components/Icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales/i18n.config';
import { OfferData, OfferState } from '../../offer.types';

import styles from './offerBadge.module.scss';

const OfferStateBadgeVariantMap = {
  neutral: null,
  recommended: 'success',
  canceled: 'error',
  withdrawn: 'warn',
  expired: 'neutral',
  selected: 'info',
  paid: 'info',
} satisfies Record<OfferState, BadgeProps['variant'] | null>;

const OfferStateIconNameMap = {
  neutral: null,
  recommended: 'thumbs-up-solid',
  canceled: 'barricade-solid',
  withdrawn: 'arrow-u-up-left-solid',
  expired: 'calendar-dots-solid',
  selected: 'seal-check-solid',
  paid: 'money-wavey-solid',
} satisfies Record<OfferState, IconName | null>;

type OfferBadgeProps = Pick<OfferData, 'state'>;

export const OfferBadge: FC<OfferBadgeProps> = ({ state }) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'status' });

  return state !== 'neutral' ? (
    <Badge
      className={styles.offerBadge}
      variant={OfferStateBadgeVariantMap[state]}
      icon={<Icon name={OfferStateIconNameMap[state]} />}
    >
      {t(state)}
    </Badge>
  ) : null;
};
