import type { Variants } from 'motion/react';

const durationOpacity = 0.16;
const durationPosition = 0.24;
const ease = 'easeOut';

export const transition = {
  duration: durationPosition,
  ease,
};

export const animation: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: durationOpacity,
      delay: durationPosition,
      ease,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: durationOpacity,
      ease,
    },
  },
};
