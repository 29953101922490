/**
 * experiments map
 *
 * key: experiment key
 * value: feature flag key
 */

export const experiments = {
  'test-experiment': 'test-flag', // a test flag to prevent breaking tests
  'direct-to-my-page-without-insurance': 'direct-to-my-page-without-insurance',
  'mypage-offer-insurance-on-accept': 'offer-insurance-on-accept',
  'has-offer-list-view': 'has-offer-list-view',
  'axo-form-version': 'axo-form-version',
};
