import { Country } from '@axo/ui-core/i18n/@types/Country.types';

/**
 * Available banks
 *
 * `code` corresponds with the `LenderID` || `Lender.ShortName`
 *
 * @note Add corresponding svg image in the `public/partners/banks` folder
 *
 */

const FI = [
  'AVI',
  'BN',
  'BON',
  'BRA',
  'BRO',
  'CRD',
  'FEF',
  'FEM',
  'FIX',
  'HAL',
  'IB',
  'KAR',
  'KB',
  'NDX',
  'NDX-alt',
  'NOR',
  'RB',
  'REM',
  'SAL',
  'SRC',
] as const;

const NO = [
  'AVI',
  'BBB',
  'BN',
  'BRA',
  'EIK',
  'EKS',
  'ESY',
  'FCB',
  'FEM',
  'GB',
  'IB',
  'IKA',
  'KB',
  'MB',
  'NDO',
  'NDX',
  'NOR',
  'OPT',
  'RB',
  'REM',
  'SCB',
  'SCB-alt',
  'SVF',
  'THR',
  'YA',
] as const;

const SE = [
  'AVI',
  'BAL',
  'BKY',
  'BN',
  'BRA',
  'BRI',
  'BRIC',
  'BRIP',
  'BRO',
  'BST',
  'CBY',
  'COL',
  'CRD',
  'EQL',
  'EUR',
  'FCB',
  'FEM',
  'FLX',
  'FRI',
  'FT',
  'FX',
  'IB',
  'ICA',
  'IKA',
  'KB',
  'LDF',
  'LSB',
  'LTP',
  'MG',
  'MMA',
  'MOA',
  'MOG',
  'NDX',
  'NNK',
  'NNT',
  'NOC',
  'NOP',
  'NSF',
  'NSM',
  'RB',
  'REM',
  'SDF',
  'ST',
  'SVK',
  'SVK-alt',
  'SVP',
  'SVP-alt',
  'TAN',
  'TF',
  'THR',
  'TRK',
  'WAS',
  'WGF',
] as const;

export const allBankCodes = [...FI, ...NO, ...SE] as const;
// 👆 is temporary to fix 👇
// FIXME data model is not ok, flatten first, country specific variations as exception (f.ex. nordax having a variant of the logo in some countries)
export const bankCodes: Partial<Record<Country, readonly BankCode[]>> = {
  FI,
  NO,
  SE,
};

export type BankCode =
  | (typeof FI)[number]
  | (typeof NO)[number]
  | (typeof SE)[number];
