import {
  useInsurancePolicy as useInsurancePolicyApi,
  useInsurancePolicyCreate,
} from '@axo/shared/data-access/hooks';
import { useEffect } from 'react';
import { getUpdateRequestState } from '../../store/data-store.utils';
import { useDataStore } from '../../store/useDataStore';

type UseInsurancePolicy = {
  apiVersion: 'v1' | 'v2';
};

export const useInsurancePolicy = ({
  apiVersion = 'v1',
}: UseInsurancePolicy) => {
  const {
    insuranceRequest,
    insurance,
    insuranceEligibility: { eligibilityID },
  } = useDataStore((state) => state.application);
  const { updateRequestState } = getUpdateRequestState('insurance');

  const { data, ...insurancePolicyQueryResult } = useInsurancePolicyApi(
    insuranceRequest.data?.PolicyID,
    apiVersion !== 'v1'
  );

  const insurancePolicyApi = useInsurancePolicyCreate();

  useEffect(() => {
    if (apiVersion === 'v2') return;
    if (insurancePolicyQueryResult.isLoading)
      updateRequestState({ status: 'loading' });
  }, [insurancePolicyQueryResult.isLoading]);

  useEffect(() => {
    if (apiVersion === 'v2') return;
    if (data) updateRequestState({ status: 'completed', data });
  }, [insurancePolicyQueryResult.isSuccess]);

  useEffect(() => {
    // !eligible or already requested the policy
    if (
      apiVersion !== 'v2' ||
      !eligibilityID ||
      insurance.data ||
      insurance.status === 'loading'
    )
      return;

    updateRequestState({ status: 'loading' });

    insurancePolicyApi.mutate(
      {
        EligibilityID: eligibilityID,
        Source: 'mypage',
      },
      {
        onSuccess: (data) => {
          updateRequestState({ status: 'completed', data });
        },
      }
    );
  }, [eligibilityID, insurance.data, insurance.status]);
};
