import type { ProductConfig } from './product-config.types';

export const productConfig: ProductConfig = {
  name: 'insurance.se',
  i18n: {
    locales: ['en-GB', 'sv-SE'],
    defaultLocale: 'sv-SE',
  },
  country: 'SE',
  currency: 'SEK',
  paymentMandateType: 'bankid',
  policy: {
    price: [
      { payout: 2000, premium: 199 },
      { payout: 3000, premium: 249 },
      { payout: 4000, premium: 299 },
      { payout: 5000, premium: 349 },
      { payout: 6000, premium: 399 },
      { payout: 7000, premium: 449 },
      { payout: 8000, premium: 499 },
      { payout: 9000, premium: 549 },
      { payout: 10000, premium: 599 },
    ],
    fractionDigits: 0,
  },
  options: {
    hasAccountNumberForm: true,
    hasSignInsuranceForm: true,
  },
};
