import {
  createInstance,
  i18n as I18n,
  InitOptions as I18nextInitOptions,
} from 'i18next';
import React, { ReactNode, useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

/**
 * Adds resource bundles to the i18n instance if not already present.
 */
const addResourceBundles = (
  i18nInstance: ReturnType<typeof createInstance>,
  localConfig: I18nextInitOptions
) => {
  const { resources, supportedLngs, ns } = localConfig;
  if (!resources || !supportedLngs || !ns) return;

  supportedLngs.forEach((lng) => {
    if (typeof lng !== 'string') return;

    const lngResources = resources[lng];
    if (!lngResources) return;

    const namespaces = Array.isArray(ns) ? ns : [ns];
    namespaces.forEach((namespace) => {
      if (!i18nInstance.hasResourceBundle(lng, namespace)) {
        const namespaceResources = lngResources[namespace];
        if (typeof namespaceResources === 'object') {
          i18nInstance.addResourceBundle(
            lng,
            namespace,
            namespaceResources,
            true,
            true
          );
        }
      }
    });
  });
};

/**
 * Component that adds I18nextProvider if it's not already in the context for components with their own translations.
 */
type I18nNsProviderProps = {
  children: ReactNode;
  localConfig: I18nextInitOptions;
};

/** @fixme client / server render issue w/ multiple instances ? */

export const I18nNsProvider: React.FC<I18nNsProviderProps> = ({
  children,
  localConfig,
}) => {
  const { i18n: existingI18n } = useTranslation();
  const hasInitializedI18n = useRef(false);

  // Create a new i18n instance using localConfig, or extend the existing one if found
  const i18nInstance = useRef<I18n>(
    existingI18n && Object.keys(existingI18n).length > 0
      ? existingI18n
      : createInstance(localConfig)
  );

  if (!hasInitializedI18n.current) {
    if (Object.keys(existingI18n).length > 0) {
      addResourceBundles(i18nInstance.current, localConfig);

      // Set the language from localConfig if provided
      if (localConfig.lng && i18nInstance.current.language !== localConfig.lng)
        i18nInstance.current.changeLanguage(localConfig.lng);
    } else {
      i18nInstance.current.init(localConfig);
      console.log('i18n instance initialized with:', localConfig);
    }
    hasInitializedI18n.current = true;
  }

  return i18nInstance.current ? (
    <I18nextProvider i18n={i18nInstance.current}>{children}</I18nextProvider>
  ) : (
    <>{children}</>
  );
};
