import { z } from 'zod';

export const AccountNumberSeSchema = z.object({
  clearingNumber: z
    .string()
    .regex(/^\d{4,5}$/, 'Clearing number must be 4 or 5 digits long')
    .refine(
      (val) => {
        const num = parseInt(val, 10);

        if (val.length === 4) return num >= 1100 && num <= 9969;

        return num >= 80000 && num <= 89999;
      },
      { message: 'Invalid clearing number' }
    ),
  accountNumber: z
    .string()
    .regex(/^\d{7,11}$/, 'Account number must be between 7 and 11 digits long'),
});

export const AccountNumberSeConstraints = {
  clearingNumber: {
    minLength: 4,
    maxLength: 5,
  },
  accountNumber: {
    minLength: 7,
    maxLength: 11,
  },
};
