import type { CardPaymentMandate } from '@axo/shared/data-access/types/insurance_payment';
import type { RequestState } from '@axo/ui-feature/components/AccountNumber/store/data-store.types';
import { useEffect } from 'react';
import { usePayexService } from '../../../../services/payex/usePayexService';
import { getUpdateRequestState } from '../../../../store/data-store.utils';
import { useDataStore } from '../../../../store/useDataStore';
import { useViewStateMachine } from '../../../../store/useViewStateMachine';

type UseCardPaymentProviderProps = {
  injectElementId: string;
};

export const useCardPaymentProvider = ({
  injectElementId,
}: UseCardPaymentProviderProps) => {
  const insurancePaymentMandate: RequestState<Partial<CardPaymentMandate>> =
    useDataStore((state) => state.application.insurancePaymentMandate);

  const { updateRequestState: updatePaymentMandateState } =
    getUpdateRequestState('insurancePaymentMandate');
  const { updateRequestState } = getUpdateRequestState(
    'insurancePaymentMandateComplete'
  );
  const { updateFormData } = useViewStateMachine();

  const completePayment = () => {
    updateRequestState({ status: 'requested' });
    updateFormData({ hasPaymentMandate: true });
  };

  const { isScriptLoaded } = usePayexService({
    injectElementId,
    scriptSrc: insurancePaymentMandate?.data?.EmbedURL || '',
    onPaymentComplete: completePayment,
  });

  useEffect(() => {
    if (
      insurancePaymentMandate.status === 'idle' &&
      insurancePaymentMandate.data === null
    ) {
      updatePaymentMandateState({ status: 'requested' });
    }
  }, [insurancePaymentMandate.status]);

  return {
    isScriptLoaded,
  };
};
