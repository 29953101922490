import { ReactNode } from 'react';
import { Switch } from '@axo/ui-core/components/input/Switch';
import { Icon } from '@axo/ui-core/components/Icon';

import styles from './InsuranceUpsell.module.scss';
import { Heading, Text } from '@axo/ui-core/components/typography';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales/i18n.config';
import { useOfferStore } from '../../store/useOfferStore';
import { ConfigService } from '@axo/config';
import { OfferCardLayout } from '../offer-card.types';
export const InsuranceUpsell = ({
  children,
  monthlyCost,
  layout,
  totalCost,
}: {
  children: ReactNode;
  layout: OfferCardLayout;
  monthlyCost: number;
  totalCost: number;
}) => {
  const hasInsuranceSelected = useOfferStore(
    (state) => state.hasInsuranceSelected
  );

  const toggleInsuranceSelected = useOfferStore(
    (state) => state.toggleInsuranceSelected
  );

  const {
    t,
    i18n: { language },
  } = useTranslation(namespace);
  const { currency } = ConfigService.get('global');

  const Currency = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  return (
    <div className={styles.wrapper} data-layout={layout}>
      <div className={styles.insurance}>
        <div className={styles.header}>
          <Icon name="shield-solid" size="xs" />
          <Heading size="xxs">
            {t('insurance.upsell.title', {
              brand: 'Axo', // TODO: get from config
            })}
          </Heading>
        </div>
        <div className={styles.content}>
          <Text className={styles.content__text} size="xs">
            <strong>
              {t('insurance.upsell.text', {
                totalCost: Currency.format(totalCost),
                monthlyCost: Currency.format(monthlyCost),
              })}
            </strong>
          </Text>
        </div>
        <div className={styles.switch}>
          <Switch
            size="l"
            checked={hasInsuranceSelected}
            onChange={toggleInsuranceSelected}
          />
          <Text size="xs" className={styles.switch__text}>
            <strong>
              {t('insurance.upsell.switchLabel', {
                monthlyCost: Currency.format(monthlyCost),
              })}
            </strong>
          </Text>
        </div>
      </div>
      <div className={styles.button}>{children}</div>
    </div>
  );
};
