import { Country } from '@axo/config/@types/Country.types';
import { IAcceptQuote } from '@axo/mypage/data-access';
import { breadcrumbs } from '@axo/mypage/util';
import { useTranslation } from '@axo/mypage/util/translation';
import {
  EventCode,
  KnownResourceType,
  useEventLogger,
  useEventResource,
} from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Modal } from '@axo/ui-core/components/Modal';
import { Text } from '@axo/ui-core/components/typography';
import { Bank } from '@axo/ui-feature/components/Partners';
import { BankCode } from '@axo/ui-feature/components/Partners/Bank';
import { useCallback, useEffect, useState } from 'react';
import styles from './ChangeAcceptOfferModal.module.scss';

type ChangeAcceptOfferModalProps = {
  oldOffer: loan_quote_presentation.EnrichedLoanQuote;
  newOffer: loan_quote_presentation.PresentedLoanQuote;
  acceptableQuoteIndex: number;
  onChangeOffer: (
    offer: loan_quote_presentation.PresentedLoanQuote,
    acceptableIndex: number
  ) => Promise<void>;
  marketCountry: Country;
  onCancel: () => void;
  accept: IAcceptQuote;
};

/** @todo extract ui → @axo/ui-feature */
export function ChangeAcceptOfferModal({
  oldOffer,
  newOffer,
  acceptableQuoteIndex,
  marketCountry,
  accept,
  onCancel,
  onChangeOffer,
}: ChangeAcceptOfferModalProps) {
  const { t } = useTranslation();
  const oldLender = oldOffer.Lender;
  const newLender = newOffer.Lender;
  const log = useEventLogger(
    ...breadcrumbs(newOffer),
    ...breadcrumbs(oldOffer)
  );

  const [isOpen, setIsOpen] = useState(false);

  useEventResource(KnownResourceType.Lender, newOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, newOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, newOffer.ID);
  useEventResource(KnownResourceType.Lender, oldOffer.LenderID);
  useEventResource(KnownResourceType.LoanRequest, oldOffer.LoanRequestID);
  useEventResource(KnownResourceType.LoanQuote, oldOffer.ID);

  useEffect(() => {
    // TODO verify trigger
    log(EventCode.OpenChangeOfferModal, { newQuoteID: newOffer.ID });
    setIsOpen(true);
  }, [log, newOffer, oldOffer]);

  const handleAccept = useCallback(async () => {
    await log(EventCode.ChangeOffer, { newQuoteID: newOffer.ID });
    await onChangeOffer(newOffer, acceptableQuoteIndex);
    setIsOpen(false);
  }, [acceptableQuoteIndex, log, newOffer, onChangeOffer]);

  const onClose = useCallback(() => {
    log(EventCode.CloseChangeOfferModal, { newQuoteID: newOffer.ID });
    onCancel?.();
    setIsOpen(false);
  }, [onCancel, log, newOffer]);

  return (
    <Modal
      open={!!oldOffer && !!newOffer}
      // open={isOpen}
      className={styles.changeAcceptOfferModal}
      hasClose
      onClose={onClose}
    >
      <Modal.Header feedbackState={'info'} hasIcon={false}>
        <Modal.Title>{t('Change your selection')}</Modal.Title>
      </Modal.Header>

      <Stack gap={'xs'} className={styles.content}>
        <Text size={'s'}>{t('You are changing your loan offer')}</Text>

        <Stack
          gap={'m'}
          direction={'h'}
          align={'center'}
          className={styles.lenders}
        >
          <Stack gap={'3xs'} className={styles.lender}>
            <Bank
              name={oldLender.Name}
              code={oldLender.ShortName as BankCode}
              country={marketCountry}
              className={styles.logo}
            />
            <Text>
              <b>{t('Currently selected')}</b>
            </Text>
          </Stack>
          <Icon name="arrow-fat-line-right-solid" />
          <Stack gap={'3xs'} className={styles.lender}>
            <Bank
              name={newLender.Name}
              code={newLender.ShortName as BankCode}
              country={marketCountry}
              className={styles.logo}
            />
            <Text>
              <b>{t('New selection')}</b>
            </Text>
          </Stack>
        </Stack>
      </Stack>

      <Modal.Actions className={styles.actions} hasSeparator>
        <Stack gap={'xs'} align={'stretch'}>
          <Text size={'s'} className={styles.cta}>
            {t('Are you sure you want to change your selection?')}
          </Text>

          <Stack gap={'3xs'} direction={'h'} align={'center'}>
            <Button variant={'tertiary'} size={'small'} onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              size={'small'}
              onClick={handleAccept}
              loading={accept.isLoading}
              disabled={accept.isLoading}
            >
              {t('Confirm and proceed')}
            </Button>
          </Stack>
        </Stack>
      </Modal.Actions>
    </Modal>
  );
}
