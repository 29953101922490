import { LenderProduct } from '@axo/shared/data-access/types/loan_quote/LoanQuote';
import { LoanInformationType } from '../../offer.types';
import { UnsecuredLoanQuote } from '../loan-quote.api.schema';

export const getLoanInformationType = (
  data: UnsecuredLoanQuote
): LoanInformationType | undefined => {
  let type: LoanInformationType | undefined = data.LoanType;

  if (
    type === 'Refinance Loan' &&
    data.LenderProduct === LenderProduct.DualOffer
  ) {
    type = 'Refinance Loan Dual Offer';
  }

  if (type === 'Combo Loan' && data.RefinanceAmount && data.CashAmount) {
    type = 'Combo Loan Detailed';
  }

  return type;
};
