import type { GlobalConfig } from '../@types/context/global-config.types';
import type { MyPageConfig } from '../@types/context/my-page-config.types';
import type { ProductUnsecuredLoanConfig } from '../@types/context/product-unsecured-loan-config.types';
import type { SiteConfig } from '../@types/context/site-config.types';
import { contactAxoNoConfig } from './presets/contact-axo-no.config';
import { globalNo } from './presets/global-no.config';
import { myPageOffersConfig } from './presets/mypage-offers.config';
import { organisationAxoNoConfig } from './presets/organisation-axo-no.config';
import { serviceTrustpilotAxoNoConfig } from './presets/service-trustpilot-axo-no.config';
import { sitePathGeminiNoConfig } from './presets/site-path-gemini-no.config';
import { unsecuredLoansBrokerCustomFields } from './presets/unsecured-loans-broker-custom-fields.config';

export const global: GlobalConfig = {
  brand: 'uloan.no',
  ...globalNo,
};

export const site: SiteConfig = {
  domain: 'uloan.no',
  theme: {
    name: 'uloan',
    brand: 'uloan.axo',
  },
  ...sitePathGeminiNoConfig,
  ...organisationAxoNoConfig,
  ...contactAxoNoConfig,
  services: {
    trustpilot: serviceTrustpilotAxoNoConfig,
  },
};

export const productUnsecuredLoan: ProductUnsecuredLoanConfig = {
  fields: unsecuredLoansBrokerCustomFields,
};

export const myPage: MyPageConfig = {
  options: { hasHighCostWarning: false, ...myPageOffersConfig },
};
