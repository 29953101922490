import { Countries } from '@axo/config/@types/Country.types';
import { LoanType } from '@axo/shared/data-access/types/loan_quote/LoanType';
import { z } from 'zod';
import { allBankCodes } from '../../../components/Partners/Bank';
import {
  LoanInformationTypes,
  LoanOfferData,
  Offer,
  OfferStates,
} from '../offer.types';
import {
  UnsecuredLoanQuote,
  UnsecuredLoanSchema,
} from './loan-quote.api.schema';
import { getBankCode } from './utils/get-bank-code';
import { getBankInfo } from './utils/get-bank-info';
import { getLoanInformationType } from './utils/get-loan-information-type';
import { getStatusFromLoanQuote } from './utils/get-status-from-loan-quote';
import { isHighCostLoan } from './utils/is-high-cost-loan';

export const LoanOfferDataSchema: z.ZodType<LoanOfferData> = z.object({
  id: z.string(),
  // LoanType: z.nativeEnum(LoanType),
  LoanType: z.nativeEnum(LoanType).optional(), // FI breaks
  state: z.enum(OfferStates),
  bank: z.enum(allBankCodes),
  country: z.enum(Countries),
  Amount: z.number(),
  Duration: z.number(),
  durationTotalMonths: z.number(),
  MonthlyPayment: z.number(),
  EffectiveRate: z.number(),
  NominalRate: z.number(),
  StartupFee: z.number(),
  AdministrationFee: z.number(),
  RequiresDocumentation: z.boolean(),
  RefinanceAmount: z.number().optional(),
  TopupAmount: z.number().optional(),
  CashAmount: z.number().optional(),
  // informationType: z.enum(LoanInformationTypes),
  informationType: z.enum(LoanInformationTypes).optional(), // FI breaks
  isHighCost: z.boolean(),
  bankInfo: z.array(z.string()).optional(),
});

export const LoanOfferTransform = (data: UnsecuredLoanQuote) =>
  ({
    type: 'loan',
    data: LoanOfferDataSchema.parse({
      id: data.ID,
      LoanType: data.LoanType,
      bank: getBankCode(data),
      country: data.MarketCountry,
      state: getStatusFromLoanQuote(data),
      Amount: data.Amount,
      RefinanceAmount: data.RefinanceAmount ?? undefined,
      TopupAmount: data.TopupAmount ?? undefined,
      CashAmount: data.CashAmount ?? undefined,
      Duration: data.Duration,
      durationTotalMonths: data.Duration * 12 + data.DurationMonths,
      MonthlyPayment: data.MonthlyPayment,
      EffectiveRate: data.EffectiveRate,
      NominalRate: data.NominalRate,
      StartupFee: data.StartupFee,
      AdministrationFee: data.AdministrationFee,
      RequiresDocumentation: data.RequiresDocumentation,
      informationType: getLoanInformationType(data),
      isHighCost: isHighCostLoan(data),
      bankInfo: getBankInfo(data),
    }),
  } as Offer);

export const LoanOfferSchema =
  UnsecuredLoanSchema.transform(LoanOfferTransform);
