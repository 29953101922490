import { OfferStatusBar } from '@axo/mypage/feature';

import { MyPageFlags } from '@axo/mypage/util';

import { InsuranceEligibilityContextProvider } from '@axo/shared/auth/crossdomain/product/InsuranceEligibilityContext';

import {
  useLoanApplication,
  useLoanQuotePresentation,
} from '@axo/shared/data-access/hooks';
import { loan_quote_accept } from '@axo/shared/data-access/types';
import { useFlags } from '@axo/shared/services/feature-flags';
import { Skeleton } from '@axo/ui-core/components/Skeleton';
import { ContentLayout } from '@axo/ui-feature/features/MyPageLayout';
import { ComponentType } from 'react';
import {
  ProgressBarWithDescription,
  useProgressBarWithDescription,
} from '../ApplicationProgress';
import { useApplicationProgressAnalytics } from './hooks/useApplicationProgressAnalytics';
import { useHeaderInfo } from './hooks/useHeaderInfo';
import { Presentation } from './Presentation/Presentation';
import { PresentationWithInsurance } from './Presentation/PresentationWithInsurance';

const apiBaseUrl = import.meta.env.VITE_APP_API_URL;

export function LoanQuotePresentation({
  applicationID,
  AcceptOfferModalWithInsurance,
}: {
  applicationID: string;
  /** @deprecated */
  AcceptOfferModalWithInsurance: ComponentType<loan_quote_accept.TAcceptOfferModal>;
}) {
  const application = useLoanApplication(applicationID);
  const quotes = useLoanQuotePresentation(applicationID);
  const {
    'show-my-page-communication-elements': flag_show_communication_elements,
    'offer-insurance-on-accept': flag_offer_insurance_on_accept,
    'accept-insurance-widget': flag_accept_insurance_widget,
  } = useFlags(MyPageFlags);

  // HeaderInfo logic
  useHeaderInfo({ application: application.data, quotes: quotes.data });

  // Progressbar logic
  const { offersUnlocked } = useProgressBarWithDescription({
    application: application.data,
    quotes: quotes.data,
  });

  // Application progress tracking
  useApplicationProgressAnalytics({ quotes: quotes.data });

  const isWithInsurance =
    flag_offer_insurance_on_accept || flag_accept_insurance_widget;

  return (
    <>
      {flag_show_communication_elements ? (
        <ProgressBarWithDescription />
      ) : (
        <OfferStatusBar applicationID={applicationID} />
      )}
      {!application.data || quotes.isLoading || !quotes.data ? (
        <Skeleton />
      ) : (
        // TODO replace with skeleton loader of offer card
        // <LoadingGears text={t('Loading your offers') as string} />
        <ContentLayout.Area area={'quotes'}>
          {isWithInsurance ? (
            <InsuranceEligibilityContextProvider
              apiUrl={apiBaseUrl}
              marketCountry={application.data.MarketCountry}
              employmentStatus={application.data.EmploymentStatus ?? undefined}
              personID={application.data.PersonID ?? undefined}
            >
              <PresentationWithInsurance
                applicationData={application.data}
                quotesData={quotes.data}
                AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
                isUnlockStep={
                  flag_show_communication_elements ? offersUnlocked : true
                }
              />
            </InsuranceEligibilityContextProvider>
          ) : (
            <Presentation
              application={application.data}
              quotes={quotes.data}
              AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
              isUnlockStep={
                flag_show_communication_elements ? offersUnlocked : true
              }
            />
          )}
        </ContentLayout.Area>
      )}
    </>
  );
}
