import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { SkeletonImage } from '../SkeletonImage/SkeletonImage';
import { CreditCardCode } from './credit-cards.types';

import styles from './creditCard.module.scss';

type CreditCardProps = {
  code: CreditCardCode;
  name?: string;
  className?: string;
} & HTMLDataAttributes;

/**
 * CreditCard component
 */
export const CreditCard = ({
  code = 'BN',
  name,
  className,
  ...props
}: CreditCardProps): ReactElement => {
  return (
    <div className={clsx(styles.creditCard, className)} {...props}>
      <SkeletonImage
        src={`/partners/credit-card/${code}.svg`}
        alt={`${name ?? code}`}
        className={styles.image}
      />
    </div>
  );
};
