import { useEffect } from 'react';
import { getUpdateRequestState } from './data-store.utils';
import { useDataStore } from './useDataStore';
import { useViewStateMachine } from './useViewStateMachine';

const initializeSubscribers = () => {
  const { updateRequestState: updateInsurancePaymentMandateCompleteState } =
    getUpdateRequestState('insurancePaymentMandateComplete');
  const { goToNextStep, updateFormData, setIsLoading } =
    useViewStateMachine.getState();

  const unsubscribePaymentMandateProviderResponse = useDataStore.subscribe(
    (state) => state.application.input?.paymentMandateProviderResponse,
    (paymentMandateProviderResponse) => {
      if (paymentMandateProviderResponse) {
        updateInsurancePaymentMandateCompleteState({ status: 'requested' });
      }
    }
  );

  const unsubscribePaymentMandateComplete = useDataStore.subscribe(
    (state) => state.application.insurancePaymentMandateComplete,
    (insurancePaymentMandateComplete) => {
      if (insurancePaymentMandateComplete?.status === 'completed') {
        updateFormData({ hasPaymentMandate: true });
        goToNextStep();
      }
    }
  );

  const unsubscribeIsEligible = useDataStore.subscribe(
    (state) => state.application.insuranceEligibility.isEligible,
    (isEligible) => {
      if (isEligible !== null) {
        setIsLoading(false);
      }
    }
  );

  return () => {
    unsubscribePaymentMandateProviderResponse();
    unsubscribePaymentMandateComplete();
    unsubscribeIsEligible();
  };
};

export const useInitializeDataStoreSubscribers = () => {
  useEffect(() => {
    const cleanup = initializeSubscribers();
    return cleanup;
  }, []);
};
