import type { GlobalConfig } from '../@types/context/global-config.types';
import type { MyPageConfig } from '../@types/context/my-page-config.types';
import type { ProductUnsecuredLoanConfig } from '../@types/context/product-unsecured-loan-config.types';
import type { SiteConfig } from '../@types/context/site-config.types';
import { contactAxoSeConfig } from './presets/contact-axo-se.config';
import { globalSe } from './presets/global-se.config';
import { myPageOffersConfig } from './presets/mypage-offers.config';
import { organisationAxoSeConfig } from './presets/organisation-axo-se.config';
import { serviceTrustpilotAxoSeConfig } from './presets/service-trustpilot-axo-se.config';
import { sitePathZmartaSeConfig } from './presets/site-path-zmarta-se.config';
import { unsecuredLoansBrokerCustomFields } from './presets/unsecured-loans-broker-custom-fields.config';

export const global: GlobalConfig = {
  brand: 'zmarta.se',
  ...globalSe,
};

export const site: SiteConfig = {
  domain: 'zmarta.se',
  theme: {
    name: 'zmarta',
    brand: 'zmarta',
  },
  ...sitePathZmartaSeConfig,
  ...organisationAxoSeConfig,
  ...contactAxoSeConfig,
  services: {
    trustpilot: serviceTrustpilotAxoSeConfig,
  },
};

export const productUnsecuredLoan: ProductUnsecuredLoanConfig = {
  fields: unsecuredLoansBrokerCustomFields,
};

export const myPage: MyPageConfig = {
  options: { hasHighCostWarning: true, ...myPageOffersConfig },
};
