import { Button } from '@axo/ui-core/components/Button';
import { Icon } from '@axo/ui-core/components/Icon';
import { useCSSVariable } from '@axo/ui-core/utils/useCSSVariable';

import { clsx } from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../config/offers.config';
import { namespace } from '../locales/i18n.config';
import { Offer, OfferType } from '../offer.types';
import { useOfferStore } from '../store/useOfferStore';
import { OfferBadge } from './_elements/OfferBadge';
import { CreditCardOffer } from './CreditCard/CreditCardOffer';
import { LoanOffer } from './Loan/LoanOffer';
import { IOfferView } from './offer-card.types';

import styles from './offerCard.module.scss';
import { InsuranceUpsell } from './InsuranceUpsell/InsuranceUpsell';

const OfferTypeContentViewMap: {
  [K in OfferType]: IOfferView<Extract<Offer, { type: K }>['data']>;
} = {
  loan: LoanOffer,
  'credit-card': CreditCardOffer,
};

type OfferCardProps = Offer & {
  className?: string;
};

/**
 * OfferCard component
 */
export const OfferCard: FC<OfferCardProps> = ({
  type,
  data,
  className,
  ...props
}) => {
  const { t } = useTranslation(namespace);
  const select = useOfferStore((state) => state.select);
  const hasInsuranceEnabled = useOfferStore(
    (state) => state.hasInsuranceEnabled
  );
  const isSelectEnabled = useOfferStore((state) => state.isSelectEnabled);

  const isSelected = data.state === 'selected';

  const isRecommendedOrSelected = data.state === 'recommended' || isSelected;
  const hasDetails = type === 'loan';
  const isPrimaryActionEnabled = isSelectEnabled || isSelected;

  const offerInsurance =
    isSelected && config.hasInsuranceUpsell && hasInsuranceEnabled;

  const [isOpen, setIsOpen] = useState<boolean>(isRecommendedOrSelected);
  const toggleOpen = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    setIsOpen(isRecommendedOrSelected);
  }, [isRecommendedOrSelected]);

  const ViewComponent = OfferTypeContentViewMap[type] as IOfferView<
    typeof data
  >;

  const primaryActionLabel = offerInsurance
    ? t('insurance.upsell.actionLabel')
    : isSelected
    ? t('actions.gotoBank')
    : t('actions.select');

  const { value: isHorizontalLayout = false, elRef } = useCSSVariable(
    '--is-horizontal-layout'
  );

  const onSelect = useCallback(() => select(data), [data]);

  const actionButton = (text: string) => (
    <Button
      className={styles.primary}
      variant={'primary'}
      size={'l'}
      onClick={onSelect}
      disabled={!isPrimaryActionEnabled}
    >
      {text}
    </Button>
  );

  const ActionWrapper = offerInsurance
    ? ({ children }: { children: React.ReactNode }) => (
        <InsuranceUpsell
          monthlyCost={config.insuranceExample?.monthlyCost ?? 5858}
          totalCost={config.insuranceExample?.totalCost ?? 300}
          layout={isHorizontalLayout ? 'horizontal' : 'vertical'}
        >
          {children}
        </InsuranceUpsell>
      )
    : React.Fragment;

  return (
    <div
      className={clsx(styles.offerCard, className)}
      {...props}
      data-is-open={isOpen ? '' : undefined}
      data-offer-state={data.state}
    >
      <OfferBadge state={data.state} />

      <div className={styles.offerCard__nested} ref={elRef}>
        <div className={styles.content}>
          <ViewComponent
            data={data}
            layout={isHorizontalLayout ? 'horizontal' : 'vertical'}
            showDetails={isOpen}
          />
        </div>
        <div className={styles.actions}>
          {!config.isExpandedVariant && (
            <Button
              className={styles.secondary}
              variant={'tertiary'}
              size={'s'}
              suffix={<Icon name="caret-down" />}
              onClick={toggleOpen}
              data-is-hidden={
                !hasDetails || isRecommendedOrSelected ? '' : undefined
              }
            >
              {t('actions.detail')}
            </Button>
          )}
          <ActionWrapper>{actionButton(primaryActionLabel)}</ActionWrapper>
        </div>
      </div>
    </div>
  );
};
