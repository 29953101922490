import {
  CreditCardCode,
  creditCardCodes,
} from '../../../../components/Partners/CreditCard';
import { CreditCardQuote } from '../loan-quote.api.schema';

export const getCreditCardCode = (data: CreditCardQuote): CreditCardCode => {
  const code: CreditCardCode = data.Lender?.ShortName as CreditCardCode;

  if (!creditCardCodes.includes(code)) {
    throw new Error(`Invalid CreditCardCode (Lender.ShortName): ${code}.`);
  }

  return code;
};
