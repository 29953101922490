export const Contexts = [
  'global',
  'env',
  'site',
  'product-credit-card',
  'product-insurance',
  'product-unsecured-loan',
  'my-page',
] as const;
export type Context = (typeof Contexts)[number];
