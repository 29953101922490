import { Offer } from '../offer.types';
import { Product } from '@axo/shared/data-access/types/loan_quote/LoanQuote';

import { CreditCardOfferTransform } from './credit-card-offer.schema';
import { LoanOfferTransform } from './loan-offer.schema';
import { EnrichedLoanQuoteSchema } from './loan-quote.api.schema'; // export const OfferSchema = z.discriminatedUnion('type', [

const transformer: Record<Product, unknown> = {
  UnsecuredLoan: LoanOfferTransform,
  CreditCard: CreditCardOfferTransform,
};

export const OfferSchema = EnrichedLoanQuoteSchema.transform((data) => {
  const transform = transformer[data.Product] as (data: unknown) => Offer;

  if (!transform) {
    throw new Error(`Unsupported product type: ${data.Product}`);
  }

  return transform(data);
});
