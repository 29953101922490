import type { Brand } from './@types/Brand.types';
import type { Context } from './@types/Context.types';
import { Contexts } from './@types/Context.types';
import { registry } from './registry/_registry';
import type { ConfigTypes } from './store/config-store.types';

/**
 * Configurations for a specific brand and contexts.
 *
 * @param brand The brand identifier (e.g., "axofinans.se").
 * @param contexts (Optional) Array of contexts to load (e.g., ['global', 'site']). Defaults to all contexts.
 */

export const getBrandConfig = (
  brand: Brand,
  contexts: Context[] = [...Contexts]
): Partial<ConfigTypes> => {
  if (!registry[brand]) {
    throw new Error(`Unknown brand : "${brand}".`);
  }

  try {
    const configModule = registry[brand];

    return contexts.reduce((acc, context) => {
      const contextConfig =
        configModule[context] ?? configModule[toCamelCase(context)]; // `my-page` or `myPage`

      return contextConfig && Object.keys(contextConfig).length > 0
        ? {
            ...acc,
            [context]: contextConfig,
          }
        : acc;
    }, {} as Record<string, any>);
  } catch (error: any) {
    throw new Error(
      `Failed to find configuration for brand "${brand}": ${error.message}`
    );
  }
};

function toCamelCase(str: string): string {
  return str.replace(/-([a-z])/g, (_, char) => char.toUpperCase()); // Convert `-x` to `X`
}
