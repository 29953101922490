import {
  useInsurancePersonIsEligible,
  usePerson,
} from '@axo/shared/data-access/hooks';
import { useEffect, useMemo } from 'react';
import { useDataStore } from '../../store/useDataStore';

type UseIsEligible = {
  apiVersion: 'v1' | 'v2';
};

export const useIsEligible = ({ apiVersion }: UseIsEligible) => {
  const { application, setIsEligible, setEligibilityID, setPerson } =
    useDataStore();
  const isEligible = useDataStore(
    (state) => state.application.insuranceEligibility.isEligible
  );

  const { data: personData } = usePerson(
    isEligible === null ? application.loan?.PersonID || '' : ''
  );

  const useInsurancePersonIsEligibleParams = useMemo(() => {
    if (apiVersion === 'v1') {
      return {
        apiVersion,
        params: {
          PersonID: application.loan?.PersonID || '',
          PersonAge: personData?.Age?.toString() || '',
          EmploymentStatus: application.loan?.EmploymentStatus || '',
          MarketCountry: application.loan?.MarketCountry || '',
        },
      };
    }

    return {
      apiVersion,
      params: {
        Brand: application.loan?.Meta?.Brand || '',
        PersonID: application.loan?.PersonID || '',
        CustomerID: application.loan?.CustomerID || '',
        ClientID: '',
        LoanApplicationID: application.loan?.ID || '',
        Source: 'mypage',
        FirstName: personData?.FirstName || '',
        LastName: personData?.LastName || '',
        MarketCountry: application.loan?.MarketCountry || '',
      },
      skip: !application.loan || !personData,
    };
  }, [apiVersion, application, personData]);

  const {
    eligibilityID,
    isEligible: _isEligible,
    personName,
  } = useInsurancePersonIsEligible(useInsurancePersonIsEligibleParams);

  useEffect(() => {
    if (_isEligible === undefined) return;

    setIsEligible(_isEligible);

    if (eligibilityID) {
      setEligibilityID(eligibilityID);
    }

    if (personData?.SocialSecurityNumber) {
      setPerson({
        socialSecurityNumber: personData.SocialSecurityNumber,
        firstName: personName?.firstName,
        lastName: personName?.lastName,
      });
    }
  }, [_isEligible, eligibilityID, personData]);
};
