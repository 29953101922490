import type { AccountNumberLabels, FAQ, Term } from './content.types';
import type { en } from './en';

// TODO
export const fi: Partial<typeof en> = {
  product: {
    name: 'Axolaina Maksuturva',
  },
  amountSelector: {
    intro:
      'Haluatko lisätä Maksuturva-vakuutuksen? Maksuturva-vakuutus voi olla turvana sinulle, jos elämässäsi tapahtuu muutoksia, liittyivätpä ne työtilanteeseesi tai terveyteesi.',
    labels: {
      currency: '€',
      month: 'kk',
      premium: 'Kuukausimaksu',
      payout: 'Vakuutuskorvaus',
      payoutInfo:
        'Voit saada korvausta valitsemaltasi korvaustasolta kun vakuutus astuu voimaan ja hyväksymis- ja karenssiaika ovat ohi',
    },
  },
  navigation: {
    intro: 'Näytä lisätietoja vakuutuksesta',
    next: {
      INTRO: 'Kyllä, jatka vakuutuksen kanssa',
      BUY: 'Osta vakuutus',
      SIGN: 'Vahvista ja maksa',
      PAYMENT_AUTHORIZE: 'Sign',
    },
    skip: 'Ei kiitos, en halua vakuutusta',
    disable: 'No, thanks',
    enable: 'Lue lisää vakuutuksesta',
  },
  steps: {
    intro: {
      usps: [
        'Vakuutuskorvaus <strong>{{insuredAmount}}/kk</strong> työttömyyden ja/tai pitkäaikaissairauden tapauksessa jopa 12 kuukauden ajan',
        'Rahat maksetaan suoraan tilillesi, ja voit käyttää niitä haluamallasi tavalla',
        'Vakuutus on peruutettavissa milloin tahansa',
        'Kertakorvaus <strong>5 000 €</strong> kuolemantapauksessa sairauden tai onnettomuuden seurauksena',
      ],
    },
    buy: {
      intro: 'Miten tiedän, sopiiko tämä vakuutus tarpeisiini?',
      faqs: <FAQ[]>[
        {
          title: 'Kuluttajan vaatimukset ja tarpeet',
          body: '<p>Tämän vakuutuksen pääasiallinen tarkoitus on kuukausittainen korvaus tahattoman työttömyyden sattuessa. Vakuutus on ensisijaisesti suunniteltu kuluttajille, joilla saattaa olla vaikeuksia kattaa menojaan tai jotka tarvitsevat suurempaa taloudellista turvaa tahattoman työttömyyden tai sairastumisen varalta.</p><p>Suosittelemme, että arvioit vakuutuksen sopivuutta itsellesi seuraavan neljän vaiheen avulla:</p><p><ol><li>Laadi budjetti, jotta saat yleiskuvan kuukausittaisista menoistasi siinä tapauksessa, että jäisit työttömäksi.</li><li>Harkitse muita mahdollisia tulonlähteitä tällaisessa tilanteessa (esim. työttömyysetuudet, säästöt, muut varallisuuserät jne.) ja mieti, riittäisikö tämä tulotaso sinulle.</li><li>Jos vaiheessa 2 arvioitu kokonaistulo on matala, harkitse, haluatko ottaa vakuutuksen, joka tarjoaa kuukausittaisen korvauksen jopa 12 kuukauden ajan tahattoman työttömyyden tai sairastumisen sattuessa.</li><li>Muista, että vakuutuksen maksama korvaus tahattoman työttömyyden tapauksessa voi olla enintään 60 prosenttia keskimääräisestä nettopalkastasi kolmen irtisanomista edeltävän kuukauden ajalta.</li></ol></p><p>Vakuutus kattaa myös sairaudesta tai tapaturmasta johtuvan kuoleman ja maksaa 5 000 € kertakorvauksen kuolinpesälle, jota voidaan käyttää hautajaiskuluihin.</p>',
        },
        {
          title: 'Vakuutuksen edut',
          usps: [
            'Voit itse päättää, mihin käytät korvauksen, kuten esimerkiksi vuokraan, lainanhoitokuluihin tai ruokaan.',
            'Vakuutus seuraa sinua, ei lainaa. Tämä tarkoittaa, että vakuutus pysyy voimassa, vaikka maksaisit lainasi pois tai vaihtaisit lainantarjoajaa.',
          ],
        },
        {
          key: 'documents',
          title: 'Yksityiskohtaiset tiedot vakuutuksesta',
          links: [
            {
              label: 'Vakuutustietoasiakirja',
              href: '/insurance.fi/docs/ProductInformation.pdf',
              download: 'AxolainaMaksuturva.pdf',
              icon: 'file-pdf-solid',
            },
            {
              label: 'Etämyynnin ennakkotiedot',
              href: '/insurance.fi/docs/PrePurchaseInformation.pdf',
              download: 'EtämyynninEnnakkotiedotAxolainaMaksuturva.pdf',
              icon: 'file-pdf-solid',
            },
          ],
        },
      ],
      salesTerms: {
        intro: 'Hyväksyn ehdot ja vahvistan lukeneeni vakuutusehdot.',
        terms: <Term[]>[
          {
            name: 'general',
            label:
              'Täytän alla mainitut ehdot ja vahvistan, että olen lukenut ja hyväksynyt <a href="/insurance.fi/docs/TermsAndConditions.pdf" download="Terms and Conditions.pdf">ennakkotiedot ja täydelliset vakuutusehdot</a>.',
            sub: [
              {
                name: 'age',
                label: 'Olen 18-65-vuotias',
              },
              {
                name: 'citizenship',
                label:
                  'Asun vakituisesti Suomessa ja minulla on voimassa oleva Kela-kortti',
              },
              {
                name: 'employment',
                label:
                  'Olen vakituisessa työsuhteessa ja työskentelen vähintään 16 tuntia viikossa',
              },
              {
                name: 'coverage',
                label:
                  'Olen täysin työkykyinen enkä ole tietoinen tulevasta lomautuksesta tai irtisanomisesta',
              },
              {
                name: 'health',
                label:
                  'Minulla ei ole kroonisia sairauksia enkä ole tietoinen tulevasta sairauslomasta. Ymmärrän, että vakuutus kattaa vain vakuutuskauden aikana sattuvat sairaudet ja tapaturmat',
              },
            ],
          },
          {
            name: 'loan',
            label:
              'Ymmärrän, että Tuloturva-vakuutus on voimassa <strong>riippumatta siitä, hyväksytäänkö lainahakemukseni</strong>',
          },
        ],
      },
    },
    sign: {
      accountNumber: {
        title: 'Account Details',
        description:
          'Since you have chosen Security Insurance, the insurance premium will be deducted monthly from this account via direct debit.',
        note: 'The account number must be a transaction account (not a savings account) and must personally belong to you.',
        form: <AccountNumberLabels>{
          clearingNumber: {
            name: 'clearingNumber',
            label: 'Clearing Number',
            placeholder: '1100',
            validation: {
              required: 'This field is required',
              invalid:
                'Clearing number must contain only digits and be exactly 4 characters long, and must be between 1100 and 9969',
            },
          },
          accountNumber: {
            name: 'accountNumber',
            label: 'Account Number',
            placeholder: '0123456789',
            validation: {
              required: 'This field is required',
              invalid: 'Account number must be between 7 and 10 digits long',
            },
          },
        },
      },
      signInsurance: {
        intro: 'Ehdot:',
        term: <Term>{
          name: 'sign',
          body: '<p>Minä {{personName}} hyväksyn, että Axolaina voi milloin tahansa veloittaa rekisteröityä maksukorttiani vakuutusmaksujen kattamiseen ilman erillistä lupaa ennen jokaista veloitusta vakuutustodistuksen mukaisesti.</p><p>Takaan, että olen sen maksukortin haltija, jonka rekisteröin Axolainalle.</p><p>Kuukausittain veloitettavat summat ja vakuutuksen ehdot näkyvät vakuutustodistuksessa tai muussa ilmoituksessa, ja ne voivat vaihdella laskutuskohtaisesti.</p><p>Olen vastuussa tietojeni päivittämisestä Axolainalle muutoksien ilmaantuessa.</p><p>Tämä valtakirja on voimassa valtakirjan allekirjoituspäivästä sihen asti, kunnes se kirjallisesti peruutetaan.</p><p><strong>Päivämäärä:</strong> {{todayDate}}</p><p><strong>Valtakirjan antaja (kortinhaltija):</strong> {{personName}}, {{personSSN}}</p><p><strong>Valtakirjan vastaanottaja:</strong> Axo Finance Oy, 2984343-1 ("Axolaina")</p>',
          label:
            '<p><strong>Valtakirjan vahvistus</strong></p><p>Olen lukenut ja hyväksynyt edellä mainitut ehdot ja valtakirjan</p>',
        },
        note: '',
      },
    },
    paymentMandate: {
      title: '',
      message: {
        warn: 'We could not complete your insurance payment because you cancelled. Please try again if you want to go ahead.',
        error:
          'We could not complete your insurance payment due to a technical error. Please try again.',
      },
      sign: 'Sign with BankID',
    },
    confirmation: {
      title: 'Onnittelut uudesta Maksuturva-vakuutuksestasi!',
      description:
        'Sähköposti, jossa on vakuutuksesi tiedot, lähetetään sinulle 24 tunnin kuluessa. Jos sinulla on kysyttävää, ota rohkeasti yhteyttä.',
    },
  },
};
