import { Card } from '@axo/ui-core/components/Card';
import { Heading } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { USPsListItem } from './Item/USPsListItem';

import styles from './uspsList.module.scss';

type USPsListProps = {
  children?: ReactNode;
  className?: string;
  as?: 'div' | 'Card';
  header?: ReactNode;
  title?: string;
  gap?: '4xs' | '3xs' | '2xs' | 'xs';
} & HTMLDataAttributes;

/**
 * USPsList component
 */
const USPsListRoot = ({
  children,
  className,
  as,
  header,
  title,
  gap = 'xs',
  ...props
}: USPsListProps): ReactElement => {
  const isCard = as === 'Card';
  const Element = isCard ? Card : as || 'div';
  const HeaderElement = isCard ? Card.Header : 'span';
  const ContentElement = isCard ? Card.Content : 'span';

  return (
    <Element
      className={clsx(styles.uspsList, className)}
      spacing={isCard ? 'medium' : undefined}
      data-card={isCard ? 'true' : undefined}
      data-gap={gap}
      {...props}
    >
      {(title || header) && (
        <HeaderElement className={styles.header}>
          {header}
          {title && <Heading className={styles.title}>{title}</Heading>}
        </HeaderElement>
      )}
      <ContentElement className={styles.content}>
        <ul>{children}</ul>
      </ContentElement>
    </Element>
  );
};

export const USPsList = Object.assign(USPsListRoot, {
  Item: USPsListItem,
});
