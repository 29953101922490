export const nb_creditCards = {
  creditCardsInfo: {
    BN: {
      name: 'Bank Norwegian',
      benefit: 'Gebyrfritt kontantuttak',
      usps: [
        'Cashpoints eller cashback på alle kjøp',
        'Reise og dagligvareforsikring inkludert',
        'Gebyrfri bruk',
      ],
      example:
        'Eff. rente 24,4&nbsp;%, 15&nbsp;000&nbsp;kr over 1&nbsp;år. Kostnad: 1&nbsp;849&nbsp;kr. Total: 16&nbsp;849&nbsp;kr',
    },
    BBB: {
      name: 'TF Bank Mastercard',
      benefit: 'Helt gebyrfritt',
      usps: [
        'Omfattende fordelsprogram',
        'Fleksibel betaling og ingen gebyrer',
        'Gratis reise- og avbestillingsforsikring',
      ],
      example:
        'Eff. rente 26,69&nbsp;%, 25&nbsp;000&nbsp;kr over 1&nbsp;år. Kostnad: 3&nbsp;337&nbsp;kr. Totalt: 28&nbsp;337&nbsp;kr',
    },
    KB: {
      name: 'Morrow Bank Mastercard',
      benefit: 'Bonus på alle kjøp',
      usps: [
        'Helt gebyrfritt',
        'Reise- og avbestillingsforsikring, ID- tyveriforsikring og kjøpsforsikring inkludert',
        'Tjen bonuspoeng på kjøp',
      ],
      example:
        'Eff. rente 28,77&nbsp;%, 12&nbsp;000&nbsp;kr over 1&nbsp;år. Kostnad: 1&nbsp;726&nbsp;kr. Totalt: 13&nbsp;726&nbsp;kr',
    },
    REM: {
      name: 're:member',
      benefit: 'Rabatter i over 200 butikker',
      usps: [
        'Apple Pay og Google Pay for enkel betaling',
        'Gode rabatter i over 200 nettbutikker',
        'Gratis reise- og avbestillingsforsikring',
      ],
      example:
        'Eff. rente 30,33&nbsp;%, 15&nbsp;000&nbsp;kr over 1&nbsp;år. Kostnad: 1&nbsp;717&nbsp;kr. Totalt: 16&nbsp;717&nbsp;kr',
    },
    IB: {
      name: 'Instapay',
      benefit: 'Markedets laveste rente',
      usps: [
        'En av markedets laveste renter',
        'Kortet for deg som betaler ned litt over tid',
        'Få eksklusive tilbud og rabatter',
      ],
      example:
        'Eff. rente 21,66&nbsp;%, 45&nbsp;000&nbsp;kr over 1&nbsp;år. Kostnad: 4&nbsp;963&nbsp;kr. Totalt: 49&nbsp;963&nbsp;kr',
    },
  },
};
