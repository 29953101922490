import type { MyPageConfig } from '../../@types/context/my-page-config.types';

export const myPageOffersConfig: Pick<MyPageConfig['options'], 'offers'> = {
  offers: {
    isExpandedVariant: true,
    hasLoanType: false,
    hasDocumentationInfo: false,
    hasInsuranceUpsell: false,
    insuranceExample: {
      //  TODO: adjust name after exact implementation is clear
      monthlyCost: 350, // values might be flexible based on the loan amount
      totalCost: 5858,
    },
  },
};
