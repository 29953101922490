import type { Brand } from '../@types/Brand.types';

import * as axofinansNo from './axofinans.no';
import * as axofinansSe from './axofinans.se';
import * as axolainaFi from './axolaina.fi';
import * as centumNo from './centum.no';
import * as freedomfinanceSe from './freedomfinance.se';
import * as freedomrahoitusFi from './freedomrahoitus.fi';
import * as lendmeDk from './lendme.dk';
import * as lendmeNo from './lendme.no';
import * as uloanNo from './uloan.no';
import * as zmartaNo from './zmarta.no';
import * as zmartaSe from './zmarta.se';
import * as zmartaFi from './zmarta.fi';

export const registry: Partial<Record<Brand, any>> = {
  'axofinans.no': axofinansNo,
  'axofinans.se': axofinansSe,
  'axolaina.fi': axolainaFi,
  'centum.no': centumNo,
  'freedomfinance.se': freedomfinanceSe,
  'freedomrahoitus.fi': freedomrahoitusFi,
  'lendme.dk': lendmeDk,
  'lendme.no': lendmeNo,
  'uloan.no': uloanNo,
  'zmarta.no': zmartaNo,
  'zmarta.se': zmartaSe,
  'zmarta.fi': zmartaFi,
};
