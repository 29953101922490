import { clsx } from 'clsx';
import { ReactElement } from 'react';

import styles from './separator.module.scss';

export const variants = ['solid', 'dashed'] as const;
export const colors = ['dark', 'medium', 'light', 'white'] as const;

type SeparatorProps = {
  variant?: (typeof variants)[number];
  color?: (typeof colors)[number];
  orientation?: 'horizontal' | 'vertical';
  decorative?: boolean;
  className?: string;
} & HTMLDataAttributes;

/**
 * Separator component
 */
export const Separator = ({
  variant = 'solid',
  color = 'medium',
  orientation = 'horizontal',
  decorative = true,
  className,
  ...props
}: SeparatorProps): ReactElement => {
  return (
    <div
      role={decorative ? undefined : 'separator'}
      className={clsx(styles.separator, className)}
      aria-orientation={orientation}
      data-orientation={orientation}
      data-variant={variant}
      data-color={color}
      {...props}
    ></div>
  );
};
