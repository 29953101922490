import { LoanType } from '@axo/shared/data-access/types/loan_quote/LoanType';
import { LoanInformationType, OfferState } from '../offer.types';

export const en = {
  actions: {
    /** validated */ select: 'Choose',
    detail: 'Details',
    gotoBank: 'Go to bank',
  },
  status: {
    recommended: 'Recommended' /** validated */,
    canceled: 'Canceled' /** validated */,
    withdrawn: 'Withdrawn',
    expired: 'Expired',
    selected: 'Chosen' /** validated */,
    paid: 'Paid out' /** validated */,
  } satisfies Record<Exclude<OfferState, 'neutral'>, string>,
  labels: {
    /** validated */ day_one: '{{count}} day',
    day_other: '{{count}} days',
    month_one: '{{count}} month',
    month_other: '{{count}} months',
    year_one: '{{count}} year',
    year_other: '{{count}} years',
    yes: 'yes',
    no: 'no',
    required: 'required',
  },
  loan: {
    amount: 'Loan amount' /** validated */,
    duration: 'Duration' /** validated */,
    effectiveRate: 'Effective Interest' /** validated */,
    monthlyPayment: 'Monthly Payment' /** validated */,
    nominalRate: 'Nominal Interest' /** validated */,
    startupFee: 'Startup Fee' /** validated */,
    administrationFee: 'Administer Fee' /** validated */,
    loanType: 'Loan type',
    documentation: {
      required: 'Requires Documentation',
      notRequired: 'No Documentation Needed',
    },
    types: {
      'Consumer Loan': 'Consumer Loan',
      'Refinance Loan': 'Refinance Loan',
      'Combo Loan': 'Combo Loan',
      'Topup Loan': 'Topup Loan',
    } satisfies Record<LoanType, string>,
    information: {
      'Consumer Loan':
        'The loan offer is a consumer loan where you can use the money for whatever you want.',
      'Refinance Loan':
        'The loan offer is a refinancing loan where old and expensive debt is replaced with a cheaper loan.',
      'Combo Loan':
        'The loan offer is a combination of refinancing loan and consumer loan, where part of the loan goes to replace old and expensive debt, and the rest is paid into your account.',
      'Topup Loan':
        'The loan offer above includes your existing loan with this bank.',
      'Refinance Loan Dual Offer':
        'This loan offer is an alternative refinancing loan based on information from the debt register, which repays more of your debt for a cheaper loan.',
      'Combo Loan Detailed':
        'The loan offer is a combination of refinancing loan and consumer loan, where {{refinanceAmount}} goes to replace old and expensive debt, and {{cashAmount}} is paid to your account.',
    } satisfies Record<LoanInformationType, string>,
    offersStatus: {
      received: 'Received: {{count}}',
    },
  },
  creditCard: {
    /** validated */ effectiveRate: 'Effective Rate',
    creditLimit: 'Credit Limit',
    interestFreePeriod: 'Interest Free Period',
  },
  note: {
    pending: 'Pending bank answer... please wait',
    information: 'Useful Information' /** validated */,
    statusDescription: {
      canceled: {
        title: 'Offer canceled / rejected',
        description: '',
        action: true,
      },
      withdrawn: {
        title: 'Offer withdrawn',
        description:
          'We are working on improving your offer. A new better offer will be available soon.',
      },
      expired: {
        title: 'Offer expired',
        description:
          'Unfortunately this offer is no longer valid. You can contact us to try and get the offer renewed.',
        action: true,
      },
    } satisfies Record<
      Extract<OfferState, 'canceled' | 'withdrawn' | 'expired'>,
      { title: string; description: string; action?: boolean }
    >,
  },
  insurance: {
    upsell: {
      title: '{{brand}} expense insurance',
      text: 'Cover your loan monthly cost of {{totalCost}} with just {{monthlyCost}}/month!',
      switchLabel: 'Add insurance for {{monthlyCost}}/month',
      actionLabel: 'Gå videre',
    },
  },
};
