import { ConfigService } from '@axo/config';
import { LoanQuoteList, Login, loginURL, Main } from '@axo/mypage/feature';
import { MyPageFlags } from '@axo/mypage/util';
import { useTypedFlags } from '@axo/shared/services/feature-flags';
import { MarketCountryCode } from '@axo/shared/types';
import '@axo/shared/ui/core/styles/base.scss';
import { ThemeProvider } from '@axo/ui-core/themes';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';
import { AcceptOfferModalWithInsurance } from './components/AcceptOfferModalWithInsurance';

ConfigService.initialize('axofinans.no');

function App() {
  const {
    flag_show_credit_cards,
    flag_show_next_steps,
    flag_show_bankID_login,
    flag_show_otp_login,
  } = useTypedFlags(MyPageFlags);

  const showLoginPage = flag_show_bankID_login || flag_show_otp_login;

  return (
    <ThemeProvider theme={'axo-finans'}>
      <div className={styles.app}>
        <Routes>
          <Route path="/" element={<Main loginPageShown={showLoginPage} />}>
            {showLoginPage && (
              <Route
                path={loginURL}
                element={
                  <Login
                    showBankID={flag_show_bankID_login}
                    showOTP={flag_show_otp_login}
                    market={MarketCountryCode.Norway}
                  />
                }
              />
            )}
            <Route
              index
              element={
                <LoanQuoteList
                  /* AcceptOfferModalWithInsurance is passed down as a prop because it is defined in the app.
                  Since it is not allowed to import from apps to libs, it has to be passed as a prop.
                  When the insurance components for each market is unified to a single component
                  AcceptOfferModalWithInsurance can be moved to a lib. */
                  AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
                  hasTabs={flag_show_credit_cards}
                  hasNextSteps={flag_show_next_steps}
                />
              }
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
