import { DataAccessContext } from '@axo/shared/data-access/provider';
import { useFlags } from '@axo/shared/services/feature-flags';
import { useContext, useEffect } from 'react';
import { ConfigService } from '../../config/ConfigService';
import { useDataStore } from '../../store/useDataStore';
import { useCreateInsurancePaymentMandate } from './useCreateInsurancePaymentMandate';
import { useCreateInsurancePaymentMandateComplete } from './useCreateInsurancePaymentMandateComplete';
import { useCreateInsuranceRequest } from './useCreateInsuranceRequest';
import { useInsurancePolicy } from './useInsurancePolicy';
import { useIsEligible } from './useIsEligible';
import { useLoanApplication } from './useLoanApplication';
import { useNoop } from './useNoop';

type APIVersions = 'v1' | 'v2';

const useInitializeCreateInsuranceRequest: Record<APIVersions, () => void> = {
  v1: useCreateInsuranceRequest,
  v2: useNoop('Create insurance request'),
};

export const useInitializeApiService = () => {
  const { 'insurance-api-version': apiVersion = 'v2' } = useFlags({
    'insurance-api-version': 'insurance-api-version',
  }) as {
    'insurance-api-version': APIVersions;
  };

  const _apiVersion = apiVersion || 'v1';

  // sync server-side state
  useLoanApplication();
  useIsEligible({
    apiVersion: _apiVersion,
  });
  useInitializeCreateInsuranceRequest[_apiVersion]();
  useInsurancePolicy({
    apiVersion: _apiVersion,
  });
  useCreateInsurancePaymentMandate({
    paymentMandateType: ConfigService.config.paymentMandateType,
  });
  useCreateInsurancePaymentMandateComplete({
    paymentMandateType: ConfigService.config.paymentMandateType,
  });

  // set auth on data access context when token becomes available on client-side state

  const { auth } = useDataStore();
  const { dispatch } = useContext(DataAccessContext);

  useEffect(() => {
    if (auth.token) {
      dispatch({
        type: 'Set auth',
        scope: { parentType: 'user' },
        payload: {
          JWT: auth.token,
        },
      });
    }
  }, [auth.token]);
};
