import { OfferState } from '../offer.types';

export const sortStatePriority: Record<OfferState, number> = {
  paid: 1,
  recommended: 2,
  selected: 3,
  neutral: 4,
  canceled: 5,
  withdrawn: 5, // Same priority as canceled
  expired: 6,
};
