import { LoanQuoteStatus } from '@axo/shared/data-access/types/loan_quote/LoanQuoteStatus';
import { OfferState } from '../../offer.types';
import { EnrichedLoanQuote } from '../loan-quote.api.schema';

export const getStatusFromLoanQuote = (data: EnrichedLoanQuote): OfferState => {
  let status: OfferState = 'neutral';

  if (data.Recommended) status = 'recommended';

  if (data._QuoteState === 'cancelled') status = 'canceled';

  if (data._QuoteState === 'accepted') {
    status =
      data.Status === LoanQuoteStatus.LoanApprovedForDisbursementDisbursedByBank
        ? 'paid'
        : 'selected';
  }

  return status;
};
