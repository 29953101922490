import { Button, Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '@axo/mypage/util/translation';

import styles from './ChangeMySelection.module.scss';

/** @deprecated */
export const ChangeMySelection = ({
  toggleShowChangeSelection,
}: {
  toggleShowChangeSelection: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.acceptedButtons}>
      <Button
        variant="white"
        onClick={toggleShowChangeSelection}
        classes={{ root: styles.link }}
        endIcon={
          <Icons.Chevron
            size="xs"
            classes={{ root: styles.chevron }}
            color={{ primary: '--dark-500' }}
          />
        }
      >
        {t('Change my selection')}
      </Button>
    </div>
  );
};
