import type { SiteConfig } from '../../@types/context/site-config.types';

export const organisationContactLendmeDkConfig: Pick<
  SiteConfig,
  'organisation' | 'contact'
> = {
  organisation: {
    name: 'LendMe',
    number: '37445290',
  },
  contact: {
    phone: '70 60 62 62',
    email: 'kundeservice@lendme.dk',
  },
};
