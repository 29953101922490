import type { EnvConfig, EnvFile } from '../@types/context/env-config.types';
import { envSchema } from '../@types/context/env-config.types';

export function parseEnv(env: EnvFile = getRuntimeEnv()): EnvConfig {
  const rawEnv = { ...(env || {}) }; // Capture all environment variables as raw input
  const filteredEnv = filterEnvBySchema(rawEnv, envSchema as EnvFile);

  const parsedConfig: EnvConfig = {
    __vars__: filteredEnv, // Store all raw variables under `__vars__`
    /**
     * @note don't base any code on this (f.ex. if production then ...)
     * aim to have the value in/from this config service
     * (exceptions: initialising 3rd party services before the config is available)
     */
    env: (filteredEnv.MODE ??
      filteredEnv.NEXT_PUBLIC_ENV ??
      envSchema.NEXT_PUBLIC_ENV) as EnvConfig['env'],
    services: {
      apiUrl:
        filteredEnv.VITE_APP_API_URL ??
        filteredEnv.NEXT_PUBLIC_API_URL ??
        envSchema.NEXT_PUBLIC_API_URL,
      integrationApiUrl:
        filteredEnv.VITE_APP_INTEGRATION_URL ??
        filteredEnv.NEXT_PUBLIC_INTEGRATION_API_URL ??
        envSchema.NEXT_PUBLIC_INTEGRATION_API_URL,
      wssUrl:
        filteredEnv.VITE_APP_WS_URL ??
        filteredEnv.NEXT_PUBLIC_WSS_URL ??
        envSchema.NEXT_PUBLIC_WSS_URL,

      wlUrl: filteredEnv.NEXT_PUBLIC_WL_URL ?? envSchema.NEXT_PUBLIC_WL_URL,
    },
  };

  return parsedConfig;
}

/** Next.js only, for client-side NEXT_PUBLIC_* as they are injected at build time  */
function getClientEnv(): EnvFile {
  return {
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_INTEGRATION_API_URL:
      process.env.NEXT_PUBLIC_INTEGRATION_API_URL,
    NEXT_PUBLIC_WSS_URL: process.env.NEXT_PUBLIC_WSS_URL,
    NEXT_PUBLIC_WL_URL: process.env.NEXT_PUBLIC_WL_URL,
  };
}

// Utility function to detect and retrieve env variables
function getRuntimeEnv(): EnvFile {
  const envVars = getEnv();
  return envVars && Object.keys(envVars).length > 0 ? envVars : getClientEnv();
}

function getEnv(): EnvFile | undefined {
  // Next.js/Node.js server-side
  if (typeof process !== 'undefined' && process.env) {
    return (process.env as EnvFile);
  }

  // Vite using `import.meta.env`
  if (
    typeof import.meta !== 'undefined' &&
    import.meta.env &&
    typeof import.meta.env === 'object'
  ) {
    return (import.meta as unknown as { env: EnvFile }).env;
  }

  return {};
}

function filterEnvBySchema<T extends Record<string, string>>(
  rawEnv: Record<string, any>, // Input raw environment variables
  schema: T // The schema against which keys are filtered and defaults are applied
): Record<keyof T, string> {
  return Object.keys(schema).reduce((acc, key) => {
    const value = rawEnv[key]; // Get raw env value
    if (value) acc[key as keyof T] = value;
    return acc;
  }, {} as Record<keyof T, string>);
}
