/**
 * Available credit cards
 *
 * `code` corresponds with the `lenderShortName`
 *
 * @note Add corresponding svg image in the `public/partners/credit-cards` folder
 *
 */

export const creditCardCodes = ['REM', 'BN', 'BBB', 'IB', 'KB'] as const;
export type CreditCardCode = (typeof creditCardCodes)[number];
