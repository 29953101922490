import { breadcrumbs, useCustomerAnalytics } from '@axo/mypage/util';
import { EventCode, useEventLogger } from '@axo/shared/data-access/event-log';
import { loan_quote_presentation } from '@axo/shared/data-access/types';
import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';

type UseGoToBankParams = {
  quote?: loan_quote_presentation.EnrichedLoanQuote;
};

export const useGoToBank = ({ quote }: UseGoToBankParams) => {
  const log = useEventLogger();
  const { trackOffer } = useCustomerAnalytics();

  const goToBank = useCallback(
    async (selectedQuote?: loan_quote_presentation.EnrichedLoanQuote) => {
      const _quote = selectedQuote ?? quote;

      if (_quote?.SigningLink) {
        // track & logging
        await trackOffer({
          event: 'My Page Offer Redirected to bank',
          offer: _quote as loan_quote_presentation.PresentedLoanQuote,
        });
        await log(EventCode.GoToBank, {}, ...breadcrumbs(_quote));

        // redirect to bank
        window.location.href = _quote.SigningLink;

        return;
      }

      Sentry.captureEvent({
        message: 'MP - GoToBank - No signing link',
        level: 'info',
        extra: {
          quote: _quote,
        },
      });
    },
    [log, trackOffer, quote]
  );

  return {
    goToBank,
  };
};
