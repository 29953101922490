import { Skeleton } from '@axo/ui-core/components/Skeleton';
import { useCardPaymentProvider } from './useCardPaymentProvider';

const divToInjectScriptID = 'payex-payment-container';

export const CardPaymentProvider = () => {
  const { isScriptLoaded } = useCardPaymentProvider({
    injectElementId: divToInjectScriptID,
  });

  return (
    <>
      {!isScriptLoaded && <Skeleton height={'500px'} />}
      <div id={divToInjectScriptID} />
    </>
  );
};
