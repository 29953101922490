import type { GlobalConfig } from '../../@types/context/global-config.types';

export const globalFi: Omit<GlobalConfig, 'brand'> = {
  country: 'FI',
  currency: 'EUR',
  i18n: {
    locales: ['fi-FI'],
    defaultLocale: 'fi-FI',
  },
};
