export const en_creditCards = {
  creditCardsInfo: {
    BN: {
      name: 'Bank Norwegian',
      benefit: 'Cash points or cashback on all purchases',
      usps: [
        'Fee-free cash withdrawals',
        'Travel and grocery insurance included',
        'No fees',
      ],
      example:
        'Eff. interest rate 24.4%, NOK 15,000 over 1 year. Cost: NOK 1,849. Total: NOK 16,849',
    },
    BBB: {
      name: 'TF Bank Mastercard',
      benefit: 'Completely fee-free',
      usps: [
        'Comprehensive benefits program',
        'Flexible payment and no fees',
        'Free travel and cancellation insurance',
      ],
      example:
        'Eff. interest rate 26.69%, NOK 25,000 over 1 year. Cost: NOK 3,337. Total: NOK 28,337',
    },
    KB: {
      name: 'Morrow Bank Mastercard',
      benefit: 'Bonus on all purchases',
      usps: [
        'Completely fee-free',
        'Travel and cancellation insurance, ID theft insurance, and purchase insurance included',
        'Earn bonus points on purchases',
      ],
      example:
        'Eff. interest rate 28.77%, NOK 12,000 over 1 year. Cost: NOK 1,726. Total: NOK 13,726',
    },
    REM: {
      name: 're:member',
      benefit: 'Discounts at over 200 stores',
      usps: [
        'Apple Pay and Google Pay for easy payments',
        'Great discounts at over 200 online stores',
        'Free travel and cancellation insurance',
      ],
      example:
        'Eff. interest rate 30.33%, NOK 15,000 over 1 year. Cost: NOK 1,717. Total: NOK 16,717',
    },
    IB: {
      name: 'Instapay',
      benefit: "The market's lowest interest rate",
      usps: [
        "One of the market's lowest interest rates",
        'The card for those who pay down over time',
        'Get exclusive offers and discounts',
      ],
      example:
        'Eff. interest rate 21.66%, NOK 45,000 over 1 year. Cost: NOK 4,963. Total: NOK 49,963',
    },
  },
};
