import { initialize } from './initialize-config-service';
import type { ConfigTypes } from './store/config-store.types';
import { useConfigStore } from './store/useConfigStore';

/**
 *
 * @usage
 *
 * ```
 * // initialize brand
 * ConfigService.initialize('axofinans.se');
 *
 * // or specific contexts
 * ConfigService.initialize('axofinans.se', ['global', 'site']);
 *
 * // register configuration
 * ConfigService.register('global', config);
 *
 * // retrieve configuration value
 * ConfigService.get('global').country;
 * ```
 *
 * @note for next.js SSR + client, call `initialize` in the `RootLayout`
 * @warn the store is global and read-only !
 * do not store request specific data here (f.ex. user state) as this will be shared between requests
 * @see https://zustand.docs.pmnd.rs/guides/nextjs
 */

export const ConfigService = {
  initialize,
  register<K extends keyof ConfigTypes>(key: K, value: ConfigTypes[K]) {
    useConfigStore().getState().register(key, value);
  },
  get<K extends keyof ConfigTypes>(key: K): ConfigTypes[K] {
    return useConfigStore().getState().get(key);
  },
  getAll(): Partial<ConfigTypes> {
    return useConfigStore().getState().getAll();
  },
};
