export type TGetInsuranceEligibilityParams = {
  personID: string;
  marketCountry: string;
  employmentStatus: string;
  personAge: number;
  apiUrl: string;
  token: string;
};

/** @deprecated */
export const getInsuranceEligibility = async ({
  employmentStatus,
  marketCountry,
  personID,
  apiUrl,
  personAge,
  token,
}: TGetInsuranceEligibilityParams) => {
  const queryParams = new URLSearchParams({
    EmploymentStatus: employmentStatus,
    MarketCountry: marketCountry,
    PersonID: personID,
    PersonAge: personAge.toString(),
  }).toString();

  const url = apiUrl.concat('/insurance/person/is-eligible', `?${queryParams}`);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

  if (!response.ok) return false;

  const responseJson = (await response.json()) as {
    isEligible: boolean;
  };

  return responseJson.isEligible;
};
