import { devtools } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';
import type { ConfigState, ConfigStore } from './config-store.types';

const defaultInitialState: ConfigState = {
  config: {},
};

const name = '@axo/config';

let _useConfigStore: ReturnType<typeof createConfigStore> | null = null;

const createConfigStore = (initialState: ConfigState = defaultInitialState) =>
  createStore<ConfigStore>()(
    devtools(
      (set, get) => ({
        ...initialState,

        register: (key, value) => {
          set(
            (state) => ({
              config: { ...state.config, [key]: value },
            }),
            undefined,
            'register'
          );
        },

        get: (key) => {
          const value = get().config[key];
          if (!value) {
            throw new Error(`Config "${String(key)}" not found.`);
          }
          return value;
        },

        getAll: () => get().config,
      }),
      { name }
    )
  );

export const initializeConfigStore = (config: ConfigState['config'] = {}) => {
  if (_useConfigStore) return _useConfigStore;

  _useConfigStore = createConfigStore({ config });
  return _useConfigStore;
};

export const useConfigStore = (): ReturnType<typeof createConfigStore> => {
  if (!_useConfigStore) {
    throw new Error(
      'Config store has not been initialized. Call `initializeConfigStore` first.'
    );
  }
  return _useConfigStore;
};
