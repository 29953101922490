export type EnvConfig = {
  __vars__: Record<string, string | undefined>;
  env: 'development' | 'test' | 'staging' | 'production';
  services: {
    apiUrl: string;
    integrationApiUrl?: string;
    wssUrl?: string;

    wlUrl?: string;
    launchDarkly?: {
      key: string;
    };
  };
};

/**
 * mapping in `env-to-store`
 *
 */

// schema & defaults
export const envSchema = {
  MODE: 'development',
  NEXT_PUBLIC_ENV: 'development',

  VITE_APP_API_URL: 'https://api.axo-test.io',
  NEXT_PUBLIC_API_URL: 'https://api.axo-test.io',

  VITE_APP_INTEGRATION_URL: 'https://integration.axo-test.io',
  NEXT_PUBLIC_INTEGRATION_API_URL: 'https://integration.axo-test.io',

  VITE_APP_WS_URL: 'wss://ws.axo.ninja',
  NEXT_PUBLIC_WSS_URL: 'wss://ws.axo.ninja',

  NEXT_PUBLIC_WL_URL: 'https://form.axo-test.io',
} as const;

export type EnvFile = Partial<Record<keyof typeof envSchema, string>>;
