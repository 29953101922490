import { useInsuranceEligibilityContext } from '@axo/shared/auth/crossdomain/product/InsuranceEligibilityContext';
import {
  loan_quote_accept,
  loan_quote_presentation,
} from '@axo/shared/data-access/types';
import { LoadedLoanApplication } from '@axo/shared/feature/providers';
import { ComponentType } from 'react';
import { Presentation } from './Presentation';

/** @deprecated */
export const PresentationWithInsurance = ({
  applicationData,
  quotesData,
  AcceptOfferModalWithInsurance,
  isUnlockStep,
}: {
  applicationData: LoadedLoanApplication;
  quotesData: loan_quote_presentation.GetQuotesResponse;
  AcceptOfferModalWithInsurance: ComponentType<loan_quote_accept.TAcceptOfferModal>;
  isUnlockStep: boolean;
}) => {
  const { isEligible, isLoading } = useInsuranceEligibilityContext();

  return (
    <Presentation
      application={applicationData}
      quotes={quotesData}
      AcceptOfferModalWithInsurance={AcceptOfferModalWithInsurance}
      isUnlockStep={isUnlockStep}
      isEligibleForInsurance={isEligible}
      isLoadingIsEligibleForInsurance={isLoading}
    />
  );
};
