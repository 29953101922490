import { setToken } from '@axo/mypage/util';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { MarketCountryCode } from '@axo/shared/types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginCard } from '../../LoginCard';
import { OTPLoginCard } from '../../OTPLoginCard';
import { Loading } from '../Loading';
import styles from './Login.module.scss';

const ROOT_PATH = '/';

export function Login({
  showOTP,
  showBankID,
  market,
}: {
  showOTP: boolean;
  showBankID: boolean;
  market: MarketCountryCode;
}) {
  const { state } = useContext(DataAccessContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const goToMain = useCallback(() => {
    location.pathname !== ROOT_PATH && navigate(ROOT_PATH);
  }, [navigate, location.pathname]);

  useEffect(() => {
    state.user.token ? goToMain() : setIsLoading(false);
  }, [state.user.token, goToMain]);

  const setAuthToken = useCallback(
    (token: string, personID?: string) => {
      setToken({ JWT: token, PersonID: personID });
      goToMain();
    },
    [goToMain]
  );

  if (isLoading) return <Loading />;

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        {showOTP && <OTPLoginCard />}
        {showBankID && (
          <LoginCard market={market} setAuthToken={setAuthToken} />
        )}
      </div>
    </div>
  );
}
