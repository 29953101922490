import type { UnsecuredLoanCustomFields } from '../../@types/context/product-unsecured-loan-config.types';

export const unsecuredLoansBrokerCustomFields: UnsecuredLoanCustomFields[] = [
  // axo
  {
    hasUnspecifiedDebtMonthlyDebtPayment: false,
  },
  // zmarta
  {
    hasUnspecifiedDebtMonthlyDebtPayment: true,
    hasExtendedEmploymentStatus: true,
    alwaysAskEmploymentStart: true,
    hasEmployerIndustry: true,
    hasJobPosition: true,
    hasApartmentType: true,
    hasHousingDebtCreditor: true,
    hasPoliticallyInfluential: true,
    hasCoApplicantMonthlyExpenses: true,
    hasCreditCardMonthlyDebtPayment: true,
    hasMainBank: true,
    hasPartnerLivingCostMonthly: true,
  },
];
