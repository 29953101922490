import { LoanType } from './LoanType';
import * as loan_request from '../loan_request';
import { LoanQuoteStatus } from './LoanQuoteStatus';
import { Broker } from '../broker';

export enum AcceptedBy {
  MyPage = 'MyPage',
  AutoAccept = 'AutoAccept',
  ACE = 'ACE',
}

export enum Product {
  UnsecuredLoan = 'UnsecuredLoan',
  CreditCard = 'CreditCard',
}

export enum LenderProduct {
  DualOffer = 'Dual Offer',
  Annuity = 'Annuity',
}

type SubLoanQuote = {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  LoanQuoteID: string;
  Duration: number;
  Amount: number;
  RefinanceAmount: number;
  TopupAmount: number;
  TotalPayment: number;
  MonthlyPayment: number;
  StartupFee: number;
  EffectiveRate: number;
  NominalRate: number;
  AdministrationFee: number;
};

/** @note the `loan-quote-presentation` does not 100% align with this schema
 * 20250117 – adding "fix me" where api does not align with this schema
 */

export interface LoanQuote {
  ID: string;

  CreatedAt: string | Date;
  UpdatedAt: string | Date;
  ActivatedAt?: string | Date;
  AcceptedAt?: string | Date;
  AcceptedBy?: AcceptedBy;
  DisbursedAt?: string | Date;

  Product: Product;
  LoanType?: LoanType;
  LenderProduct?: string;
  Broker?: Broker;

  LoanRequestID: string;
  LenderID: string;
  // FIXME not present on LQP
  ApplicationID: string;

  Status: LoanQuoteStatus;

  Amount: number;
  RefinanceAmount?: number;
  TopupAmount?: number;
  CashAmount?: number;
  /** Duration in years */
  Duration: number;
  /** DurationMonths in months of year. Between 0 and 11 */
  DurationMonths?: number;
  TotalPayment?: number;
  MonthlyPayment: number;
  StartupFee: number;
  EffectiveRate: number | null;
  NominalRate: number | null;
  AdministrationFee: number;

  LenderReference?: string;
  LenderStatus?: string;
  IsPlaceholderQuote?: boolean;
  SigningLink?: string;
  RequiresDocumentation?: boolean;
  CreditCardInterestFreeDays?: number;
  LenderMaxPossibleAmount?: number;
  SubLoanQuote?: SubLoanQuote;
}

export interface BankRequest {
  LenderID: string;
  LoanRequests: loan_request.LoanRequest[];
}

export interface BankQuote extends LoanQuote {
  LenderID: string;
  LoanRequest: LoanRequestTag;
}

export interface LoanRequestTag {
  LoanRequestID: string;
  CreatedAt: string | Date;
  CreatedBy: string;
  Status: loan_request.LoanRequestStatus | undefined;
  Selected?: boolean;
  LenderStatus?: string;
  Amount?: number;
  Duration?: number;
  LenderReference?: string;
}
