'use client';

import { useEffect, useRef, useState } from 'react';

export function useCSSVariable(CSSVar: string) {
  const elRef = useRef(null);
  const [value, setValue] = useState<string>('');

  const updateValue = () => {
    if (elRef.current) {
      const newValue = window
        .getComputedStyle(elRef.current)
        .getPropertyValue(CSSVar)
        .trim();

      setValue(newValue);
    }
  };

  useEffect(() => {
    updateValue();

    const observer = new ResizeObserver(updateValue);
    if (elRef.current) observer.observe(elRef.current);

    return () => observer.disconnect();
  }, [CSSVar]);

  return { value, elRef };
}
