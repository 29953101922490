import { clsx } from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { feedbackState } from '../../models/feedback-state.const';

import styles from './badge.module.scss';

export const styleVariants = [...feedbackState, 'disabled'] as const;
export const variants = [...feedbackState, 'disabled'] as const;
export const sizes = ['s', 'm', 'l'] as const;

export type BadgeProps = {
  children?: ReactNode;
  className?: string;
  variant?: (typeof variants)[number];
  size?: (typeof sizes)[number];
  icon?: ReactElement;
  borderless?: boolean;
} & HTMLDataAttributes;

/**
 * Badge component
 */
export const Badge = ({
  children,
  className,
  variant = 'neutral',
  size = 'm',
  icon,
  borderless = false,
  ...props
}: BadgeProps): ReactElement => {
  return (
    <div
      className={clsx(styles.badge, className)}
      data-variant={variant}
      data-size={size}
      data-borderless={borderless ? '' : undefined}
      {...props}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {children}
    </div>
  );
};
