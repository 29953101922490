import { allBankCodes, BankCode } from '../../../../components/Partners/Bank';
import { UnsecuredLoanQuote } from '../loan-quote.api.schema';

export const getBankCode = (data: UnsecuredLoanQuote): BankCode => {
  const code: BankCode = data.Lender?.ShortName as BankCode;

  if (!allBankCodes.includes(code)) {
    throw new Error(`Invalid BankCode (Lender.ShortName): ${code}.`);
  }

  return code;
};
