import { ConfigService } from '@axo/config';
import { I18nNsProvider } from '@axo/ui-core/i18n/I18nNsProvider';
import { getLanguageFromLocale } from '@axo/ui-core/i18n/utils/getLanguageFromLocale';
import { useMemo } from 'react';
import { OfferList } from './List/OfferList';
import { i18nConfig } from './locales/i18n.config';

export const Offers = () => {
  const defaultLocale = ConfigService.get('global').i18n.defaultLocale;

  const localConfig = useMemo(
    () => ({
      ...i18nConfig,
      lng: getLanguageFromLocale(defaultLocale),
    }),
    [defaultLocale]
  );

  return (
    <I18nNsProvider localConfig={localConfig}>
      <OfferList />
    </I18nNsProvider>
  );
};
