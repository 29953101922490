import { InitOptions } from 'i18next';
import { en } from './en';
import { en_creditCards } from './en.credit-cards';
import { fi } from './fi';
import { nb } from './nb';
import { nb_creditCards } from './nb.credit-cards';
import { sv } from './sv';

export const namespace = 'Offer';

const resources = {
  en: {
    [namespace]: { ...en, ...en_creditCards },
  },
  fi: {
    [namespace]: fi,
  },
  nb: {
    [namespace]: { ...nb, ...nb_creditCards },
  },
  sv: {
    [namespace]: sv,
  },
} as const;

const supportedLngs = Object.keys(resources) as Array<keyof typeof resources>;

export const i18nConfig: InitOptions = {
  resources,
  // lng: 'sv', //supportedLngs[0],
  // fallbackLng: 'sv', //supportedLngs[0],
  interpolation: {
    escapeValue: false,
  },
  ns: [namespace],
  defaultNS: namespace,
  supportedLngs,
};
