import * as RadixSwitch from '@radix-ui/react-switch';
import { clsx } from 'clsx';
import { AriaAttributes, ReactElement } from 'react';
import { InputSize, InputStateVariant } from '../InputProps.types';

import styles from './switch.module.scss';

type SwitchProps = {
  /** The state of the switch when it is initially rendered */
  defaultChecked?: boolean;
  /** The controlled state of the switch */
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  /** The name of the switch for form submission */
  name?: string;
  /** The value of the switch for form submission */
  value?: string;
  id?: string;
  state?: InputStateVariant;
  size?: InputSize;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
} & HTMLDataAttributes &
  Pick<AriaAttributes, 'aria-labelledby'>;

/**
 * Switch component
 *
 * @see https://www.radix-ui.com/primitives/docs/components/switch
 */

export const Switch = ({
  size = 's',
  className,
  defaultChecked = false,
  state = 'neutral',
  onChange,
  ...props
}: SwitchProps): ReactElement => {
  return (
    <div className={clsx(styles.switch, className)} data-size={size}>
      <RadixSwitch.Root
        className={styles.switchRoot}
        defaultChecked={defaultChecked}
        data-input-state={state}
        data-size={size}
        onCheckedChange={(checked) => onChange?.(checked)}
        {...props}
      >
        <RadixSwitch.Thumb className={styles.switchThumb} />
      </RadixSwitch.Root>
    </div>
  );
};
