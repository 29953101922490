import { EnrichedLoanQuote } from '../loan-quote.api.schema';

export const isHighCostLoan = (data: EnrichedLoanQuote): boolean => {
  let isHighCost = false;

  // keeping the data structure as is for now
  if (data.EffectiveRate > 32.75) isHighCost = true;

  return isHighCost;
};
