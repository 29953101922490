// data from ↓
import { z } from 'zod';

export const LoanQuotePresentationSchema = z
  .object({
    AcceptableLoanQuotes: z.array(z.record(z.any())),
    AcceptedLoanQuotes: z.array(z.record(z.any())),
    CancelledLoanQuotes: z.array(z.record(z.any())),
  })
  .transform(
    ({ AcceptableLoanQuotes, AcceptedLoanQuotes, CancelledLoanQuotes }) => {
      // Transform all quotes into a unified array and add the correct _QuoteState
      const quotes = [
        ...AcceptableLoanQuotes.map((quote) => ({
          ...quote,
          _QuoteState: 'selectable' as const,
        })),
        ...AcceptedLoanQuotes.map((quote) => ({
          ...quote,
          _QuoteState: 'accepted' as const,
        })),
        ...CancelledLoanQuotes.map((quote) => ({
          ...quote,
          _QuoteState: 'cancelled' as const,
        })),
      ];

      return { quotes };
    }
  );

export type LoanQuotePresentation = z.infer<typeof LoanQuotePresentationSchema>;
