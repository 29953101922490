import { ConfigService } from '@axo/config';
import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';

import { Text } from '@axo/ui-core/components/typography';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditCard } from '../../../../components/Partners';
import { namespace } from '../../locales/i18n.config';
import { CreditCardOfferData } from '../../offer.types';
import { IOfferView } from '../offer-card.types';
import styles from './creditCardOffer.module.scss';

type DetailsViewData = { label: string; value?: string };

export const CreditCardOffer: IOfferView<CreditCardOfferData> = ({
  data,
  layout = 'horizontal',
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(namespace);
  const { currency } = ConfigService.get('global');

  const Currency = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  const Percentage = new Intl.NumberFormat(language, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const infoPrefix = `creditCardsInfo.${data.creditCard}`;

  const primaryData = useMemo(
    (): DetailsViewData[] => [
      {
        label: t('creditCard.effectiveRate'),
        value: Percentage.format(data.InterestRate / 100),
      },
      {
        label: t('creditCard.creditLimit'),
        value: Currency.format(data.Amount),
      },
      {
        label: t('creditCard.interestFreePeriod'),
        value: t('labels.day', { count: data.InterestFreeDays }),
      },
    ],
    [data]
  );

  const secondaryData = useMemo(
    (): string[] =>
      t(`${infoPrefix}.usps`, { returnObjects: true }) as string[],
    [data]
  );

  return (
    <div className={styles.creditCardOffer} data-layout={layout}>
      <Stack className={styles.card}>
        <Stack className={styles.card_group}>
          <CreditCard code={data.creditCard} className={styles.creditCard} />
          <Text className={styles.name}>{t(`${infoPrefix}.name`)}</Text>
        </Stack>
        <div className={styles.benefits}>
          <Text>{t(`${infoPrefix}.benefit`)}</Text>
        </div>
      </Stack>
      <Stack className={styles.details}>
        {primaryData.map((d) => (
          <div key={d.label}>
            <span className={styles.value}>{d.value}</span>
            <span className={styles.label}>{d.label}</span>
          </div>
        ))}
      </Stack>
      <Stack className={styles.usps}>
        <ul>
          {(Array.isArray(secondaryData) ? secondaryData : []).map(
            (usp, index) => (
              <li key={index}>
                <Icon name={'badge-check-solid'} size={'m'} />
                <Text>{usp}</Text>
              </li>
            )
          )}
        </ul>
        <Text
          dangerouslySetInnerHTML={{ __html: t(`${infoPrefix}.example`) }}
        />
      </Stack>
    </div>
  );
};
