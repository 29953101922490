import { Countries } from '@axo/ui-core/i18n/@types/Country.types.ts';

/**
 * Quick and temporary i18n solution for ContactModal. Remove when config is available.
 */
export const Markets = Countries.filter(
  (country) => country !== 'GB' && country !== 'DK'
);
export type Market = (typeof Markets)[number];

type ContactModalI18nProps = {
  [key in Market]: {
    title: string;
    text: string;
    phone: string;
    email: string;
  };
};

export const i18n: ContactModalI18nProps = {
  // GB: {
  //   title: 'Need help?',
  //   text: 'Choosing a loan can be difficult, but we are here for you. Call or email us and we\'ll help you.',
  //   phone: '0123456789',
  //   email: 'customerservice@axofinance.com',
  // },
  FI: {
    title: 'Tarvitsetko apua?',
    text: 'Lainan valinta voi olla vaikeaa, mutta olemme täällä sinua varten.<br />Soita tai lähetä sähköpostia, niin autamme sinua.',
    phone: '09 4245 2326',
    email: 'kundeservice@axofinance.com',
  },
  NO: {
    title: 'Trenger du hjelp?',
    text: 'Å velge et lån kan være vanskelig, men vi er her for deg.<br />Ring eller send oss en e-post, så hjelper vi deg.',
    phone: '22 86 74 00',
    email: 'kundeservice@axofinans.no',
  },
  SE: {
    title: 'Behöver du hjälp?',
    text: 'Att välja ett lån kan vara svårt, men vi finns här för dig.<br />Ring eller maila oss så hjälper vi dig.',
    phone: '0771 323400',
    email: 'kundservice@axofinans.se',
  },
};
