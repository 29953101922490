import { captureEvent } from '@sentry/browser';
import { useEffect, useRef, useState } from 'react';

import { injectPayexScript } from './utils/injectPayexScript';

type UsePayexService = {
  // ID of the div where the Payex iframe will be injected
  injectElementId: string;
  scriptSrc: string;
  onPaymentComplete?: () => void;
};

export const usePayexService = ({
  injectElementId,
  scriptSrc,
  onPaymentComplete,
}: UsePayexService) => {
  const isScriptInjectionDone = useRef(false);
  const script = useRef<HTMLScriptElement | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const _setIsScriptLoaded = () => {
    setIsScriptLoaded(true);
  };

  const onScriptError = (error: string | Event) => {
    script.current = null;

    captureEvent({
      message: 'Payex script Error - Failed to load Payex script',
      level: 'error',
      extra: { error },
    });
  };

  const injectScript = async () => {
    const _script = await injectPayexScript({
      scriptSrc,
      rootDivId: injectElementId,
      onPaymentComplete,
      onScriptLoaded: _setIsScriptLoaded,
      onError: onScriptError,
    });

    script.current = _script;
  };

  useEffect(() => {
    if (!window || isScriptInjectionDone.current || !scriptSrc) return;

    isScriptInjectionDone.current = true;
    injectScript();

    return () => {
      if (script.current) document.body.removeChild(script.current);
    };
  }, [scriptSrc]);

  useEffect(() => {
    if (script.current) return;

    const retryScriptInjection = setInterval(() => {
      if (script.current) {
        clearInterval(retryScriptInjection);
        return;
      }

      onScriptError('Payex script failed to load, retrying...');
      injectScript();
    }, 3000);

    return () => clearInterval(retryScriptInjection);
  }, [script.current]);

  return { isScriptLoaded };
};
