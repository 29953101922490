import { ConfigService } from '@axo/config';
import type { MyPageConfig } from '@axo/config/@types/context/my-page-config.types';

let _config: MyPageConfig['options']['offers'] | null = null;

const defaultConfig: MyPageConfig['options']['offers'] = {
  isExpandedVariant: true,
  hasLoanType: false,
  hasDocumentationInfo: false,
  hasInsuranceUpsell: true,
  insuranceExample: {
    monthlyCost: 350,
    totalCost: 5858,
  },
};

export const config: MyPageConfig['options']['offers'] = new Proxy(
  {},
  {
    get: (_target, property: string) => {
      try {
        _config = ConfigService.get('my-page').options.offers;
      } catch (error) {
        _config = defaultConfig;
      }

      return _config[property as keyof MyPageConfig['options']['offers']];
    },
  }
) as MyPageConfig['options']['offers'];
