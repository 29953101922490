import { Stack } from '@axo/ui-core/components/layout/item';
import { AnimatePresence, motion, MotionConfig } from 'motion/react';
import React, { FC } from 'react';
import { OfferCard } from '../Card/OfferCard';
import { useOfferStore } from '../store/useOfferStore';
import styles from './offerList.module.scss';
import { animation, transition } from './offerList.motion';

type OfferListProps = Record<string, never>;

export const OfferList: FC<OfferListProps> = () => {
  const offers = useOfferStore((state) => state.offers);

  if (!offers.length) {
    return <div className={styles.empty}></div>;
  }

  return (
    <MotionConfig transition={transition}>
      <Stack className={styles.offerList}>
        <AnimatePresence>
          {offers.map((offer) => (
            <motion.span
              key={offer.data.id}
              layout="position"
              variants={animation}
              initial={'initial'}
              animate={'animate'}
              exit={'exit'}
            >
              <OfferCard key={offer.data.id} {...offer} />
            </motion.span>
          ))}
        </AnimatePresence>
      </Stack>
    </MotionConfig>
  );
};
