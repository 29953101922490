import { loan_application } from '@axo/shared/data-access/types';
import { useApplicationStatuses } from './useApplicationStatuses';

export function useApplicationStatusUtils() {
  const statuses = useApplicationStatuses();

  function isInAllGroups(
    status: loan_application.ApplicationStatus | undefined,
    groups: loan_application.StatusGroup | loan_application.StatusGroup[],
  ) {
    if (!statuses.data || !status) return false;

    try {
      const match = statuses.data[status];
      if (!Array.isArray(groups)) return match.Groups.includes(groups);
      return groups.every((group) => match.Groups.includes(group));
    } catch (e) {
      console.error('Status not found', status);
      return false;
    }
  }

  function isInAnyGroup(
    status: loan_application.ApplicationStatus | undefined,
    groups: loan_application.StatusGroup | loan_application.StatusGroup[],
  ) {
    if (!statuses.data || !status) return false;
    try {
      const match = statuses.data[status];
      if (!Array.isArray(groups)) return match.Groups.includes(groups);
      return groups.some((group) => match.Groups.includes(group));
    } catch (e) {
      console.error('Status not found', status);
      return false;
    }
  }

  function isInStatusList(
    status: loan_application.ApplicationStatus | undefined,
    statusCodesList: string[] | number[],
  ) {
    if (!status) {
      return false;
    }
    const statusCode = status.slice(0, 3);
    return statusCodesList.some((code) => String(code) === statusCode);
  }

  function isFinal(status: loan_application.ApplicationStatus | undefined) {
    if (!status) {
      return false;
    }
    return isInAnyGroup(status, [
      loan_application.StatusGroup.Cancelled,
      loan_application.StatusGroup.Disbursed,
    ]);
  }

  function isEditable(status: loan_application.ApplicationStatus | undefined) {
    if (!status) {
      return false;
    }
    const editableStatusCodes = [
      '100',
      '160',
      '161',
      '169',
      '180',
      '190',
      '200',
      '201',
      '209',
      '210',
    ];
    const statusCode = status.slice(0, 3);
    return editableStatusCodes.includes(statusCode);
  }

  return {
    isInAllApplicationStatusGroups: isInAllGroups,
    isInAnyApplicationStatusGroup: isInAnyGroup,
    isInApplicationStatusList: isInStatusList,
    isFinalApplicationStatus: isFinal,
    isEditableApplicationStatus: isEditable,
  };
}
