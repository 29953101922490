import type { GlobalConfig } from '../../@types/context/global-config.types';

export const globalSe: Omit<GlobalConfig, 'brand'> = {
  country: 'SE',
  currency: 'SEK',
  i18n: {
    locales: ['sv-SE'],
    defaultLocale: 'sv-SE',
  },
};
