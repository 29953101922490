import type { GlobalConfig } from '../../@types/context/global-config.types';

export const globalNo: Omit<GlobalConfig, 'brand'> = {
  country: 'NO',
  currency: 'NOK',
  i18n: {
    locales: ['nb-NO'],
    defaultLocale: 'nb-NO',
  },
};
