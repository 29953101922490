import { clsx } from 'clsx';
import { ReactElement } from 'react';
import { Country } from '../../i18n/@types/Country.types';

import AigLogo from '../../@assets/brand/aig-logo.svg?react';
import AxoInsuranceLogomark from '../../@assets/brand/axo-insurance-logomark.svg?react';

import styles from './brandInsurance.module.scss';

export const variants = ['default', 'with-provider', 'provider-only'];

const labels: Record<Country, string> = {
  GB: 'Axo income protection insurance',
  DK: 'Axo Økonomiforsikring',
  FI: 'Axolaina Maksuturva',
  NO: 'Axo utgiftsforsikring',
  SE: 'Axo Finans trygghetsförsäkring',
};

type BrandInsuranceProps = {
  country: Country;
  variant?: (typeof variants)[number];
  className?: string;
} & HTMLDataAttributes;

/**
 * BrandInsurance component
 */
export const BrandInsurance = ({
  country,
  variant = 'default',
  className,
  ...props
}: BrandInsuranceProps): ReactElement => {
  return (
    <div
      className={clsx(styles.brandInsurance, className)}
      data-variant={variant}
      {...props}
    >
      <span className={styles.brand}>
        <span className={styles.logomark}>
          <AxoInsuranceLogomark />
        </span>
        <span className={styles.label}>{labels[country]}</span>
      </span>
      <span className={styles.provider}>
        <AigLogo />
      </span>
    </div>
  );
};
