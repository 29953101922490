import type { AccountNumberLabels, Term } from './content.types';
import type { en } from './en';

export const sv: typeof en = {
  product: {
    name: 'Axo Finans trygghetsförsäkring',
  },
  amountSelector: {
    intro: 'Försäkra din ekonomi',
    labels: {
      currency: 'kr',
      month: 'mån',
      premium: 'Månadskostnad',
      payout: 'Utbetalas vid skada',
      payoutInfo:
        'Du kan få ersättning från den tidpunkt då försäkringen träder i kraft och karenstiden är över',
    },
  },
  navigation: {
    intro: 'Vill du teckna försäkring?',
    next: {
      INTRO: 'Ja, gå vidare',
      BUY: 'Teckna försäkring',
      SIGN: 'Signera',
      PAYMENT_AUTHORIZE: 'Signera',
    },
    skip: 'Nej, visa mina erbjudanden',
    disable: 'Nej, tack',
    enable: 'Läs mer om försäkring',
  },
  steps: {
    intro: {
      usps: [
        'Ersättning <b>{{insuredAmount}}/månad</b> vid arbetslöshet och/eller långtidssjukdom i upp till 12 månader',
        'Bidrar till utgifter för exempelvis boende, mat, drivmedal, lån',
        'Engångsersättning <b>100 000 kr</b> vid vissa allvarliga sjukdomar',
        'Engångsersättning <b>50 000 kr</b> vid dödsfall till följd av sjukdom eller olycka',
      ],
    },
    buy: {
      intro: '',
      faqs: [
        {
          title: 'Hur vet jag om försäkringen passar för mina behov?',
          body:
            '<p><b>Konsumentens krav och behov</b></p>' +
            '<p>Det huvudsakliga skyddet i försäkringen är en månatlig ersättning vid ofrivillig arbetslöshet eller sjukskrivning på grund av sjukdom eller olycka. Försäkringen är främst utformad för att tillgodose kraven och behoven hos konsumenter som kan ha svårt att klara av sina utgifter eller som föredrar större ekonomisk trygghet vid ofrivillig arbetslöshet eller sjukskrivning.</p>' +
            '<p>Vi rekommenderar att du använder följande fyra steg för att avgöra om försäkringen är för dig:</p>' +
            '<ol>' +
            '<li>Gör en budget för att få en översikt över vilka månatliga utgifter du inte skulle kunna dra ner på om du skulle bli ofrivilligt arbetslös eller sjukskriven.</li>' +
            '<li>Tänk över vilka andra inkomster du skulle ha i en sådan situation, exempelvis arbetslöshetsersättning från Försäkringskassan, A-kassa, andra försäkringar, sjukersättning från Försäkringskassan, din arbetsgivare eller andra försäkringar, samt sparade pengar. Fundera också på om du känner dig trygg med nivån på din totala alternativa månadsinkomst.</li>' +
            '<li>Om svaret på ovanstående är "nej", överväg om du vill teckna en försäkring som ger dig möjligheten att öka din totala alternativa månadsinkomst i upp till 12 månader vid ofrivillig arbetslöshet eller sjukskrivning.</li>' +
            '<li>Om svaret på ovanstående är "ja", kan budgeten hjälpa dig att välja ett lämpligt försäkringsbelopp per månad. Tänk på att den ersättning som betalas ut från försäkringen vid ofrivillig arbetslöshet maximalt kan uppgå till 60 procent av din genomsnittliga nettolön de senaste tre månaderna som föregick uppsägningen.</li>' +
            '</ol>' +
            '<p>Försäkringen inkluderar även skydd vid vissa allvarliga sjukdomar och ger en engångsersättning på 100 000 kronor. Vid dödsfall till följd av sjukdom och/eller olycka utbetalas ett engångsbelopp på 50 000 kronor till dödsboet, som kan användas till att exempelvis täcka begravningskostnader.</p>',
        },
        {
          title: 'Produkt- och förköpsinformation',
          links: [
            {
              label: 'Produktfaktablad',
              href: 'https://3i0wz95rphq6.b-cdn.net/6uyej14xj0bc-villkor-axo-finans-trygghetsforsakring-v-20240417.pdf',
              download: 'villkor-axo-finans-trygghetsforsakring-v-20240417.pdf',
              icon: 'file-pdf-solid',
            },
            {
              label: 'Förköpsinformation',
              href: 'https://3i0wz95rphq6.b-cdn.net/768hlidon5sw-forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf',
              download:
                'forkopsinformati-axo-finans-trygghetsforsakring-v-20240417.pdf',
              icon: 'file-pdf-solid',
            },
          ],
        },
      ],
      salesTerms: {
        intro: 'Kontrollera följande',
        terms: <Term[]>[
          {
            name: 'general',
            label:
              'Jag uppfyllar nedan krav, och bekräftar att jeg läst och godkänt <a href="https://3i0wz95rphq6.b-cdn.net/sy2o1sm0uis-forkopsinformati-villkor-axo-finans-trygghetsforsakring-v-20240417.pdf" download="forkopsinformati-villkor-axo-finans-trygghetsforsakring-v-20240417.pdf">Förköpsinformation och fullständiga villkor</a>.',
            sub: [
              {
                name: 'age',
                label:
                  'är 18-65 år, folkbokförd i Sverige och omfattas av svensk socialförsäkring',
              },
              {
                name: 'employment',
                label:
                  'har fast anställning om minst 16 h/vecka, utan kännedom om kommande arbetslöshet eller uppsägning',
              },
              {
                name: 'health',
                label:
                  'är fullt arbetsför, utan kännedom om allvarlig sjukdom eller potentiell sjukskrivning',
              },
              {
                name: 'coverage',
                label:
                  'är medveten om att försäkringen endast gäller för arbetslöshet, sjukdom eller skada som inträffar under försäkringsperioden',
              },
            ],
          },
          {
            name: 'loan',
            label:
              'Jag förstår att Trygghetsförsäkringen gäller oavsett om jag får mitt lån beviljat eller inte.',
          },
        ],
      },
    },
    sign: {
      accountNumber: {
        title: 'Bekräfta dina kontouppgifter för autogiro',
        description: '',
        note: 'Kontonumret måste vara ett transaktionskonto (ej sparkonto) och tillhöra dig personligen.',
        form: <AccountNumberLabels>{
          clearingNumber: {
            name: 'clearingNumber',
            label: 'Clearingnummer',
            placeholder: '1100',
            validation: {
              required: 'Detta fält är obligatoriskt',
              invalid:
                'Clearingnummer måste endast innehålla siffror och vara exakt 4 tecken långt, och måste vara mellan 1100 och 9969',
            },
          },
          accountNumber: {
            name: 'kontonummer',
            label: 'Kontonummer',
            placeholder: '0123456789',
            validation: {
              required: 'Detta fält är obligatoriskt',
              invalid: 'Kontonummer måste vara mellan 7 och 10 siffror långt',
            },
          },
        },
      },
      signInsurance: {
        intro: 'Signera',
        term: <Term>{
          name: 'sign',
          label:
            'Jag har läst och godkänner <a href="https://3i0wz95rphq6.b-cdn.net/e6f1d7ldtor-allmana-villkor-autogiro-20221214.pdf" download="allmana-villkor-autogiro-20221214.pdf">villkor för autogiro och hantering av personuppgifter</a>.',
        },
        note: 'Jag bekräftar köp av min Trygghetsförsäkring och autogiromedgivande genom att signera med BankID.',
      },
    },
    paymentMandate: {
      title: 'Signera avtalet för autogirobetalningar',
      message: {
        warn: 'Vi kunde inte slutföra din försäkringsbetalning eftersom du avbröt. Försök igen om du vill fortsätta.',
        error:
          'Vi kunde inte slutföra din försäkringsbetalning på grund av ett tekniskt fel. Försök igen.',
      },
      sign: 'Signera med BankID',
    },
    confirmation: {
      title: 'Grattis till din nya trygghetsförsäkring!',
      description:
        'Ett mejl med information om din försäkring kommer att skickas till dig inom 24 timmar. Tveka inte att kontakta oss om du har några frågor.',
    },
  },
};
