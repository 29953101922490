import type { StoredOffer } from '../LoanQuotePresentation.types';

function key(applicationID: string) {
  return `AcceptedOffer/${applicationID}`;
}

/** @todo can someone add the specs for this feature – why store locally if data comes from back-end ? */

export const storeAcceptedOffer = (
  applicationID: string,
  offer: StoredOffer
) => {
  sessionStorage.setItem(key(applicationID), JSON.stringify(offer));
};

export const removeStoredAcceptedOffer = (applicationID: string) => {
  sessionStorage.removeItem(key(applicationID));
};

export const getStoredAcceptedOffer = (applicationID: string) => {
  const offer = sessionStorage.getItem(key(applicationID));
  if (offer) return JSON.parse(offer) as StoredOffer;
  return null;
};
