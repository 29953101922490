import type { Brand } from './@types/Brand.types';
import type { Context } from './@types/Context.types';
import { Contexts } from './@types/Context.types';
import { parseEnv } from './env/env-to-store';
import { getBrandConfig } from './get-brand-config';
import { initializeConfigStore } from './store/useConfigStore';

export const initialize = (
  brand: Brand,
  contexts: Context[] = [...Contexts]
): void => {
  const config = getBrandConfig(brand, contexts);

  // adding env
  config.env = parseEnv();

  initializeConfigStore(config);
};
