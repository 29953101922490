import { Offer } from '../offer.types';
import { sortStatePriority } from './offer-store.config';
import { SortBy } from './offer-store.types';

/**
 * Sorts offers based on the specified criteria.
 * @param offers - Array of offers to sort.
 * @param criteria - Sorting criteria (e.g., `state`, `amount`, `effectiveRate`).
 * @returns Sorted array of offers.
 */
export const sortOffers = (
  offers: Offer[],
  criteria: SortBy = 'state'
): Offer[] => {
  return [...offers].sort((a, b) => {
    // Sorting by state
    if (criteria === 'state') {
      const stateOrderA =
        sortStatePriority[a.data.state] ?? Number.MAX_SAFE_INTEGER;
      const stateOrderB =
        sortStatePriority[b.data.state] ?? Number.MAX_SAFE_INTEGER;

      if (stateOrderA !== stateOrderB) {
        return stateOrderA - stateOrderB;
      }
    }

    // Sorting by amount (descending)
    if (criteria === 'amount' && 'Amount' in a.data && 'Amount' in b.data) {
      const amountA = a.data.Amount ?? 0;
      const amountB = b.data.Amount ?? 0;

      if (amountA !== amountB) {
        return amountB - amountA; // Higher amount first
      }
    }

    // Sorting by effective rate (ascending)
    if (
      criteria === 'effectiveRate' &&
      'EffectiveRate' in a.data &&
      'EffectiveRate' in b.data
    ) {
      const rateA = a.data.EffectiveRate ?? Infinity;
      const rateB = b.data.EffectiveRate ?? Infinity;

      if (rateA !== rateB) {
        return rateA - rateB; // Lower rate first
      }
    }

    // Default: Retain original order as a tie-breaker
    return 0;
  });
};
