import {
  useCreateInsuranceBankIdMandate,
  useCreateInsuranceBsMandate,
  useCreateInsuranceCardMandate,
  useCreateInsuranceKidMandate,
} from '@axo/shared/data-access/hooks';

import type {
  CreateBankIdPaymentMandateRequest,
  CreateBsPaymentMandateRequest,
  CreateCardPaymentMandateRequest,
  CreateKidPaymentMandateRequest,
} from '@axo/shared/data-access/types/insurance_payment';

import type { InsurancePaymentMandateType } from '../../config/payment-mandate.types';
import { useNoop } from './useNoop';

/***/

type UseCreateInsurancePaymentMandateApiImpl =
  | typeof useCreateInsuranceBankIdMandate
  | typeof useCreateInsuranceBsMandate
  | typeof useCreateInsuranceCardMandate
  | typeof useCreateInsuranceKidMandate
  | ReturnType<typeof useNoop>;

/**
 * Factory for different InsurancePaymentMandateType implementations
 *
 * used by `useCreateInsurancePaymentMandate` to handle different `paymentMandateType`s
 */

export const useCreatePaymentMandateApiImpl = (
  paymentMandateType: InsurancePaymentMandateType = 'card'
) => {
  const implMap: Record<
    InsurancePaymentMandateType,
    UseCreateInsurancePaymentMandateApiImpl | null
  > = {
    bankid: useCreateInsuranceBankIdMandate,
    bs: useCreateInsuranceBsMandate,
    card: useCreateInsuranceCardMandate,
    kid: useCreateInsuranceKidMandate,
    criipto: useNoop(`payment mandate type: ${paymentMandateType}`),
  };

  if (!implMap[paymentMandateType]) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return implMap[paymentMandateType];
};

/**
 * Helper
 */

type PaymentMandateDto =
  | CreateBankIdPaymentMandateRequest
  | CreateBsPaymentMandateRequest
  | CreateCardPaymentMandateRequest
  | CreateKidPaymentMandateRequest;

type DtoCreator = (data: CreateInputData) => PaymentMandateDto;

type CreateInputData = {
  // common base
  InsuranceID: string;
  CustomerID: string;
  PersonID: string;
  MarketCountry: string;
  // custom
  appUri?: string;
  amount: number;
  accountNumber?: string;
  clearingNumber?: string;
  registrationNumber?: string;
};

const createBankIdDto = (
  data: CreateInputData
): CreateBankIdPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  AccountNumber: data.accountNumber!,
  ClearingNumber: data.clearingNumber!,
  SourceURL: data.appUri!,
});

const createBsDto = (data: CreateInputData): CreateBsPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  Amount: data.amount,
  RegistrationNumber: data.registrationNumber,
  AccountNumber: data.accountNumber,
});

const createCardDto = (
  data: CreateInputData
): CreateCardPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  PaymentURL: window ? window.location.origin : '', // TODO
  CompleteURL: data.appUri, // TODO
  UserAgent: '', // TODO
  Amount: data.amount,
});

const createKidDto = (
  data: CreateInputData
): CreateKidPaymentMandateRequest => ({
  InsuranceID: data.InsuranceID,
  CustomerID: data.CustomerID,
  PersonID: data.PersonID,
  MarketCountry: data.MarketCountry,
  CompleteURL: data.appUri, // TODO
  Amount: data.amount,
});

const dtoCreators: Record<InsurancePaymentMandateType, DtoCreator | null> = {
  bankid: createBankIdDto,
  bs: createBsDto,
  card: createCardDto,
  kid: createKidDto,
  criipto: null,
};

export const createPaymentMandateDto = (
  paymentMandateType: InsurancePaymentMandateType = 'card',
  data: any
) => {
  const dtoCreator = dtoCreators[paymentMandateType];

  if (!dtoCreator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return dtoCreator(data);
};

/**
 * Helper
 *
 * @note keep in sync with the `refetchInterval` criteria in the hook.
 * Each `paymentMandateType` requires certain properties to be available
 * before initiating the payment mandate flow w/ external providers.
 */

const isBankIdDataComplete = (data: any): boolean => {
  return !!data?.AssentlyJWT && !!data?.AgreementDigest;
};

const isBsDataComplete = (data: any): boolean => {
  return !!data?.PaymentURL; // TODO verify
};

const isCardDataComplete = (data: any): boolean => {
  return !!data?.EmbedURL;
};

const isKidDataComplete = (data: any): boolean => {
  return !!data?.PaymentURL;
};

const dataValidators: Record<
  InsurancePaymentMandateType,
  ((data: any) => boolean) | null
> = {
  bankid: isBankIdDataComplete,
  bs: isBsDataComplete,
  card: isCardDataComplete,
  kid: isKidDataComplete,
  criipto: null,
};

export const isInsurancePaymentMandateDataComplete = (
  paymentMandateType: InsurancePaymentMandateType,
  data: any
): boolean => {
  const validator = dataValidators[paymentMandateType];

  if (!validator) {
    throw new Error(`Unsupported payment mandate type: ${paymentMandateType}`);
  }

  return validator(data);
};
