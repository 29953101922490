import type { GlobalConfig } from '../../@types/context/global-config.types';

export const globalDk: Omit<GlobalConfig, 'brand'> = {
  country: 'DK',
  currency: 'DKK',
  i18n: {
    locales: ['da-DK'],
    defaultLocale: 'da-DK',
  },
};
