import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales/i18n.config';
import { OfferData } from '../../offer.types';
import { OfferInformation, OfferInformationProps } from './OfferInformation';

export type OfferStateInformationProps = {
  state: Extract<OfferData['state'], 'canceled' | 'withdrawn' | 'expired'>;
  className?: string;
};

export const OfferStateInformation: FC<OfferStateInformationProps> = ({
  state,
  className,
}) => {
  const { t } = useTranslation(namespace);

  const labelKey = `note.statusDescription.${state}`;

  const labels = t(labelKey, {
    returnObjects: true,
  }) as OfferInformationProps['labels'];

  return (
    <OfferInformation
      state={state as OfferInformationProps['state']}
      labels={labels}
      className={className}
    />
  );
};
